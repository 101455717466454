.loginPage {
  height: 100vh;
  background: var(--backBluecol);
  width: 100%;
  display: table;
  .MuiGrid-container {
    display: table-cell;
    vertical-align: middle;
  }
  /* PAGE HEADER CSS  */
  .mainLogo {
    position: absolute;
    top: 20px;
    right: 60px;
    width: 140px;
    display: block;
  }

  .navbar.scrolling-navbar {
    padding: 30px 50px;
  }
  #navbarCollapse3 {
    display: none !important;
  }
  /*.navbar.scrolling-navbar {
    background: none !important;
    box-shadow: none;
  }*/

  /* PAGE BODY CSS  */
  .loginBox .MuiPaper-root a {
    color: #145187;
  }
  .loginBox .MuiPaper-root {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12);
    width: 380px;

    text-align: left;
    background-color: #f5f5f5;
    color: #333333;
    position: relative;
    margin: auto;
    border-radius: 10px;
  }
  .container {
    padding: 5px 40px 5px;
  }
  .loginheadbox {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 10px 10px 0 0;
    text-align: center;
  }
  .loginBox .MuiPaper-root .md-form label {
    display: none;
  }
  .form-display-info {
    margin-bottom: 25px;
  }
  .form-group {
    margin-bottom: 25px;
  }
  .form-title {
    text-align: left;
    font-weight: bold;
  }
  .contentBox span {
    color: #fff;
    text-align: left;
    display: block;
    font-size: 30px;
    padding-right: 50%;
  }
  span.text-danger {
    font-size: 12px;
  }
  h2 {
    font-size: 62px;
    font-weight: bold;
    color: #fff;
    width: 40%;
    text-align: left;
    padding-top: 40px;
  }
  button.btn-primary {
    width: 100%;
    background: #337ab7 !important;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    text-transform: capitalize;
    font-weight: 600;
  }
  .col-md-6 .form-group {
    margin: 0;
  }
  .new-user,
  .forgot-password {
    font-size: 12px;
    text-decoration: underline;
  }
  .forgot-password {
    text-align: right;
  }
  .form-check {
    position: relative;
    top: -2px;
  }
  .form-check label {
    color: #333333;
    font-size: 12px;
  }
  .new-user:hover,
  .forgot-password:hover {
    text-decoration: none;
  }
  .copyRight {
    color: #fff;
    position: absolute;
    bottom: -50px;
    left: 20px;
    font-size: 12px;
  }
  .md-form.md-outline input {
    background: #f2f2f2 !important;
  }
  .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError {
    background: #fff !important;
    color: #000;
    padding: 0px 10px !important;
    height: 94px;
  }
  .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError .MuiAlert-message {
    color: #000;
  }
  .MuiSnackbar-anchorOriginBottomCenter {
    position:absolute !important;
    top: 10px !important;
    align-items: baseline;
    width: 350px;
  }
  .MuiPaper-root {
    align-self: baseline;
    align-items: baseline;
  }
}
.loginheadbox img {
  width: 280px;
  margin-bottom: 5px;
}
.loginheadbox {
  font-size: 15px;
}
.padbox1 {
  padding: 40px 40px 10px;
  text-align: center;
}
.padbox1 h1 {
  font-size: 18px;
}
.padbox1 p {
  font-size: 14px;
}
.padbox1 .btn {
  width: 100%;
  background: #337ab7 !important;
  color: #fff;
  font-size: 12px;
  padding: 8px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 5px;
  position: relative;
  left: -2%;
}
@media only screen and (max-width: 600px) {
  .loginBox .MuiPaper-root {
    width: 100%;
  }
  .loginPage {
    padding: 0;
  }
}
.alertsuccess.MuiPaper-root.MuiAlert-root.MuiAlert-standardSuccess.MuiPaper-elevation0 {
  background: #fff !important;
  padding: 0px 10px !important;
  height: 54px;
  z-index: 999;
}
.alerterror.MuiPaper-root.MuiAlert-root.MuiAlert-standardError.MuiPaper-elevation0 {
  background: #fff !important;
  padding: 0px 10px !important;
  height: 94px;
  z-index: 999;
}

body .loginPage.errorForgotPassword .MuiSnackbar-anchorOriginBottomCenter {top:320px !important; width:300px;}
body .loginPage.errorForgotPassword .MuiSnackbar-anchorOriginBottomCenter .MuiPaper-root {background:#fff; padding:.5rem;}
body .loginPage.errorForgotPassword .MuiPaper-root.MuiAlert-root {height:auto}
body .loginPage .MuiAlert-standardSuccess .MuiAlert-icon svg {fill:#4caf50; display: none;}
body .loginPage.errorForgotPassword .MuiAlert-message{font-size: 10px; font-weight: bold}
body .loginPage.errorForgotPassword .MuiAlert-icon svg {fill:#4caf50; display: none;}

@media only screen and (max-width:600px) {
  body .loginPage.errorForgotPassword .MuiSnackbar-anchorOriginBottomCenter .MuiPaper-root {margin-left:3rem;}
  body .loginPage.errorInvalid .MuiSnackbar-anchorOriginBottomCenter {left:1.8rem}
}

