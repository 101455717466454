.p {
  margin: 0;
  padding: 0;
}
.ft20 {
  font-size: 13px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft21 {
  font-size: 13px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000080;
}
.ft22 {
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft23 {
  font-size: 13px;
  line-height: 17px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft24 {
  font-size: 13px;
  line-height: 19px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
p {
  margin: 0;
  padding: 0;
}
.ft10 {
  font-size: 16px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft11 {
  font-size: 13px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft12 {
  font-size: 13px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft13 {
  font-size: 13px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft14 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft15 {
  font-size: 13px;
  line-height: 17px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
.ft16 {
  font-size: 13px;
  line-height: 17px;
  font-family: 'Times New Roman', Times, serif, sans-serif;
  color: #000000;
}
