
.casesContainer.caseTable.no-records {
  .Component-horizontalScrollContainer-12 .MuiTable-root tr th:first-child
  ,.MuiTable-root tr th:first-child {
    display: none !important;
  }

  .MuiTable-root tbody tr td:nth-child(1) {
    display: table-cell !important;
  }
}
.eventTopPagination {display:inline-block; margin:0 0 1rem; width:100%;}
.caseTablePagination {width:calc(100% - 60%); float:left; margin:.5rem 0 0;
  p {
    margin: 0;
    font-size: small;
  }
}
.topBar .customdates .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid #ced4da;
  border-radius: 5px;
  height: 2.4rem;
  margin-top: 15px;
}
.MuiSvgIcon-root.MuiSelect-icon {
  display: none;
}
.controlDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
  .perPagecounter {
    display: inline-block;
  }
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.MuiTableCell-root {
  border-bottom: 0 !important;
}
.hold {
  background: #000 !important;
  border: #000 !important;
}
.inprogress {
  background: #f27c47 !important;
  border: #f27c47 !important;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}
html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  div
  div
  div
  div
  table.MuiTable-root
  thead.MuiTableHead-root
  tr.MuiTableRow-root.MuiTableRow-head
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  min-width: 120px !important;
}

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  table.MuiTable-root
  tfoot.MuiTableFooter-root
  tr.MuiTableRow-root.MuiTableRow-footer
  td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1
  div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.Component-paginationSelectRoot-4 {
  display: none !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
  background-color: #337ab7 !important;
  color: #fff !important;
}

.MuiTablePagination-spacer {
  display: flex !important;
  justify-content: center !important;
  flex: 0 0 0 !important;
}

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
  display: none;
}

.casesContainer {
  padding: 0;
  margin: 5rem 5px 0;
  .MuiTablePagination-input.MuiTablePagination-selectRoot {
    .MuiSelect-select.MuiSelect-select {
      border: 1.5px solid #e8e8e8;
    }
  }
  .nav-tabs {
    font: normal 16px HelveticaNeueCondensedBold, sans-serif;
    .nav-item {
      .nav-link {
        color: #000;
        &:hover {
          border:1px solid #fff;
        }
      }
      .nav-link.active {
        border: none;
        border-bottom: 2px solid #337ab7;
        color: #337ab7;
      }
    }
  }
}

.topBar {
  display: flex;
  p {
    margin: 0;
  }
  flex-direction: row;
}

.leftContainer {
  display: flex;
  align-items: center;
  width: 35%;
  .MuiSelect-icon {
    top: calc(50% - 12px);
  }
  .MuiFormControl-fullWidth {
    min-width: 25%;
    max-width: 25%;
  }
}

.rightContainer {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  min-height: 1.1876em;
  justify-content: right;
  text-overflow: ellipsis;
  align-items: center;
  width: 70% !important;
  p {
    margin: 0;
    font-size: small;
  }
}
.SelectField {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid lightgray;
}


/*BredCrum*/
ul.breadcrumb {
  padding: 0px 5px;
  list-style: none;
  li {
    display: inline;
    cursor: pointer;
    font: normal 11px HelveticaNeueMedium, sans-serif;
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        color: #01447e;
        text-decoration: underline;
      }
    }
  }
  li + li {
    &:before {
      padding: 8px;
      color: #000;
      content: " \003E";
    }
  }
}
._loading_overlay_content {
  top: 20%;
  left: 45%;
  position: fixed;
}
#casedetailtabs-tabpane-pma,#casedetailtabs-tabpane-decision
{
  ._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk
  {
  overflow-y: hidden;
  }
}

._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk
{
  overflow-y: hidden;
}
._loading_overlay_content.css-42igfv{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 10%;
  
}
._loading_overlay_wrapper{
  height: 100vh;
  //overflow: hidden;
}
._loading_overlay_overlay.css-df17o1{
  height: 100vh;
  
}
.LoadingData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 10%;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(236, 243, 248, 0.7);
  z-index: 9999;
  iframe {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: visible;
  }
}

.stickyDiv {
  position: relative;
  top: 0;
}

.breadHeader {
  position: relative;
  background: #fff;
  .breadcrumb {
    width: 98%;
    padding: 15px 5px 5px;
    margin: -2.4rem 0 0;
    display: block;
    line-height: 13px;
    text-align: left;
    min-height: 4rem;
  }
  .breadcrumb,
  .mb-3.nav-tabs {
    position: fixed;
    z-index: 4;
    background: #fff;
  }
  .mb-3.nav-tabs {
    width: 98% !important;
    top: 6.5rem;
    background: #fff;
  }
  .tab-content {
    margin: 5rem 0 0;
    padding: 5rem 0 0;
  }
}

.leftContainer.filterContainer {
  margin-left: 6px;
}
#filterField {
  width: 40% !important;
}
.MuiToolbar-root.MuiToolbar-regular.jss5.MuiToolbar-gutters {
  display: none;
}
.dataTable {
  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
  .MuiFormControl-root .MuiInputAdornment-root .material-icons {
    display: none;
  }
  .react-date-picker__wrapper {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid #ced4da;
    border-radius: 5px;
    height: 2.4rem;
    width: 100% !important;
  }
  .SelectField {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    border: 1px solid #ced4da;
    border-radius: 5px !important;
    padding:6px 5px 7px !important;
    width: 100% !important;
  }

  
  .col-md-3 {margin:0; width:calc(100% - 86.4%);}
  .col-md-3:nth-child(3) {width:calc(100% - 83%);}
}
.filtersbtn .btn {
  background-color: #337ab7 !important;
  box-shadow: none;
  border-radius: 3px;
  font-size: 14px;
  font-family: HelveticaNeueCondensedBold, sans-serif;
  padding: 5px 20px;
  min-width: 115px;
  text-transform: capitalize;
}
.filtersbtn {
  text-align:left; padding-left:5px; margin:.7rem 0 0;
}
.dataTable .react-date-picker__button svg {
  display: inherit;
  width: 1.2vw;
}
.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  display: none !important;
}
.caseTable {
  .MuiTable-root tr th:nth-child(1),
  .MuiTable-root tr td:nth-child(1) {
    width: 0 !important;
    display: none !important;
  }

  /*NAM ID Column*/
  .MuiTable-root tr th:nth-child(2),
  .MuiTable-root tr td:nth-child(2) {
    width: 7.35rem !important;
  }
    
    /*NAM Caption Column*/
    .MuiTable-root tr th:nth-child(3),
    .MuiTable-root tr td:nth-child(3) {
      width: 25.25rem !important;
      z-index: 1 !important;
    }

    .MuiTable-root tr th:nth-child(4),
    .MuiTable-root tr td:nth-child(4) {
      width: 7.75rem !important; white-space:nowrap;
    }

    .MuiTable-root tr th:nth-child(5),
    .MuiTable-root tr td:nth-child(5) {
      width: 6.25rem !important;
      white-space:nowrap;
    }

    .MuiTable-root tr th:nth-child(7),
    .MuiTable-root tr td:nth-child(7) {
      width: 10.5rem !important;
      white-space:nowrap;
    }
  }

  

.page {width:calc(100% - 40%); float:left; text-align:right;
  .row {float:right;}
  .paginator {
    button:disabled {border:1px solid #ccc}
    button {border:1px solid #333; padding:.3rem .5rem;}
  }
}
.tooltip1 {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 320px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}


.casesContainer.caseTable
{
div.main-div > div
{
  overflow-x: visible !important;
  overflow-y: visible !important;
}
}

.eventTypes {
  .eventList {display:inline-block;}
  svg, p {display:inline-block; margin-right:.5rem;}
}


.showDiv {display:flex; margin-bottom:15px;}
.showingResContainer {display:inline-block; margin:0 .5rem}
.paginDiv {display:inline-block; float:right;}
.paginDiv .row {display:inline-block;}
.TeritaryDelete.fa.fa-trash {background:#ccc; margin:-.3rem 0 0; border:0; font-size:18px; padding:5px 10px;}

@media only screen and (max-width:1185px) {
  .dataTable .col-md-3 {width:calc(100% - 83%); margin-bottom:1rem;}
  .dataTable .col-md-3:nth-child(3) {width:calc(100% - 70%);}  
}
@media only screen and (max-width:890px) {
  header .navbar-collapse .navbar-nav .nav-item {height:44px !important; line-height:20px;}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(3) {line-height:16px !important; padding:7px 0 !important;}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(5) {height:44px !important; line-height:42px !important;}
}
@media only screen and (max-width:825px) {
  .casegridFilter{
    margin-right: 15px !important;
    margin-left: 0px !important;
    font-size: 15px;
    font-weight: 600;
  }
  .paginDiv {margin:1rem 0 0;}
  .dataTable .col-md-3 {width:calc(100% - 67%); margin-bottom:1rem;}
  .dataTable .col-md-3:nth-child(3) {width:calc(100% - 66.7%);}
}
@media only screen and (max-width:810px) {
  .casegridFilter{
    margin-right: 15px !important;
    margin-left: 0px !important;
    font-size: 15px;
    font-weight: 600;
  }
.dataTable .col-md-3 {width:calc(100% - 50%); margin-bottom:1rem;}
.dataTable .col-md-3:nth-child(3) {width:calc(100% - 50%);}
body .filtersbtn {text-align:left; margin-top:0 !important;}
.caseTablePagination, .page {width:100%;}
.perPagecounter {float:left;}
}
@media only screen and (max-width:600px) {
  .casegridFilter{
    margin-right: 15px !important;
    margin-left: 0px !important;
    font-size: 15px;
    font-weight: 600;
  }
body .filtersbtn {text-align:left; margin:0 !important;}
.controlDiv {width:100%; margin:1rem 0 0;}
.showDiv {display:block; width:100%;}
.paginator {text-align:center; margin:1rem 0 0;}
.nav-fill .nav-item {width:7rem}
.dataTable .col-md-3:nth-child(3) {width:99%;}
body .breadHeader .breadcrumb {margin:-2.9rem 20px 0 0; width:auto; padding:1rem 5px 1rem; position:relative;}
.mbMobile-1 {margin-bottom:1rem !important;}
body .breadHeader .mb-3.nav-tabs {position:relative; top:0;}
body .breadHeader .tab-content {margin:0; padding:0;}
.paginDiv {margin:0;}
body .dataTable .react-date-picker__button svg {width:3.2vw;}

header .navbar-collapse .navbar-nav .nav-item {height:auto !important; line-height:normal; margin-bottom:.5rem;}
body header .navbar-collapse .navbar-nav .nav-item:nth-child(3) {line-height:normal !important;}
header .navbar-collapse .navbar-nav .nav-item.active {padding-bottom:.5rem;}

}
@media only screen and (max-width:480px) {
  .casegridFilter{
    margin-right: 15px !important;
    margin-left: 0px !important;
    font-size: 15px;
    font-weight: 600;
  }
  .nav-fill .nav-item {width:100%;}
  
  body .breadHeader .mb-3.nav-tabs {top:0; width:92% !important;}
} 
.disabled-icon{
  cursor: default !important;
}
.ccircle {
  border-radius: 100px;
    padding: 0.1rem .3rem;
    background: rgb(242, 124, 71);
    color: #fff;
    font-size: 9px;
    margin-right: 0.3rem; font-weight:bold;
}

.casesContainer.caseTable{

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}
.casegridFilter{
  margin-right: 15px !important;
    margin-left: 0px !important;
    font-size: 15px;
    font-weight: 600;
}