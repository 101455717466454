.changePass{
    height: calc(100% - 0px);
    background: red;
    width: calc(100% - -32px);
    // margin: -1rem;
    .main-container{
    padding: 0;
    overflow-x: hidden;
}
.homePage .analytics-section .MuiPaper-rounded{
    background-color:#F5F5F5 !important;
    color: black;
    padding: 0 !important;
}
.grey-text{
    color:black !important;
    text-align: left !important;
    font-weight:600;
}
.homePage{
    height: 100%;
    background-color: #145187;
    padding: 0 !important;
    margin: 0;
    overflow-x: hidden;
}
.col-md-12{
    text-align: center;
}
.form-group{
    text-align: left !important;
}
.analytics-section{
    padding: 0 !important;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
}
.loginheadbox img{
    width: 280px;
    margin-bottom: 5px;
   }
   
   .loginheadbox{
    background-color: #fff;
    padding: 20px 40px 1px;
    border-radius: 10px 10px 0 0;
    text-align: center;
    
   }
   .loginBox .MuiPaper-root a {color:#145187;}
  .loginBox .MuiPaper-root {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12); 
    width: 380px;
    
   text-align: left; background-color:#F5F5F5; 
    color: #333333; position: relative; margin: auto; border-radius: 10px;}
    .horizontal{
        padding: 5px 40px 20px;
    }
    .horizontal .form-title{
        font-size: 18px;
    padding: 5px 40px 5px;
    font-weight: 600;
    }
    .horizontal .btn-primary {
        width: 100%;
    background: #337AB7 !important;
    color: #fff;
    font-size: 14px;
    padding: 8px;
    text-transform: capitalize;
    font-weight: 600;
    margin-left: -1px;
}
.loginPage .form-title{
    text-align: center !important;
    padding-bottom: 0px !important;
    margin-bottom: 0px;

}
 
#newPassword ,#confirmPassword {
    padding: 0.375rem 0.75rem !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}