.calendersec {
  padding: 75px 20px 0 20px;
  height: calc(100% - 20px);
  .rbc-calendar {
    .rbc-toolbar-label {
      font: normal 30px HelveticaNeueCondensedBold, sans-serif;
      color: #145187;
    }
    .rbc-btn-group {
      font: normal 13px HelveticaNeueBold, sans-serif;
      .rbc-active {
        background-color: #145187;
        color: #fff;
      }
    }
    .rbc-month-header {
      font: normal 16px HelveticaNeueMedium, sans-serif;
      span {
        padding: 0.65rem 0;
        display: inline-flex;
      }
    }
  }
  .rbc-current {
    a {
      color: #337ab7;
    }
  }
  .rbc-today {
    border: 2px solid #337ab7;
  }
}



.rbc-toolbar button.rbc-active {
  color: #fff !important;
  background-color: #145187 !important;
  border-color: #8c8c8c;
}
.rbc-toolbar .rbc-toolbar-label {
  color: #145187;
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
}
.rbc-off-range-bg {
  background: #f8f8f8;
}
.rbc-today {
  background-color: #fafdff;
  border: 2px solid #337ab7 !important;
  color: #337ab7 !important;
}
.rbc-current a {
  color: #337ab7 !important;
}
.rbc-date-cell > a,
.rbc-date-cell > a:active,
.rbc-date-cell > a:visited {
  color: inherit;
  text-decoration: none;
  position: relative;
  right: 20px;
  top: 10px;
  font-size: 20px;
}
.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  font-size: 16px;
}
.tooltip-inner {
  padding: 10px 5px !important;
  font-size: 14px !important;
}
.tooltip-inner .form-control-plaintext {
  padding: 3px !important;
  font-size: 13px !important;
  width: 100% !important;
}
.tooltip-inner .btn-primary {
  padding: 10px 10px !important;
  background: #337ab7 !important;
  font-size: 12px !important;
}
.rbc-row-segment {
  position: relative;
  top: 15px; z-index:0;
}
.tooltip {
  opacity: 1 !important;
  box-shadow: 0px 0px 7px lightgray !important;
  inset: -140px auto auto 0px !important;
  pointer-events: none;
}
.rbc-month {
  color: #145187;
  font-size: 26px;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}
.rbc-year {
  color: #145187;
  font-size: 26px;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}
.date1 button {
  border-radius: 50% !important;
  height: 20px !important;
  width: 20px !important;
  padding: 0 !important;
  border: 2px solid #c7c7c7 !important;
  position: relative;
  top: -2px; margin:0 .5rem !important;
}
.date1 .prev-icon {
  color: #c7c7c7 !important;
  font-size: 20px !important;
  position: relative !important;
  top: -5px !important;

  .rbc-toolbar button.rbc-active {
    color: #fff !important;
    background-color: #145187 !important;
    border-color: #8c8c8c;
  }
  .rbc-toolbar .rbc-toolbar-label {
    color: #145187;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 700;
  }
  .rbc-off-range-bg {
    background: #f8f8f8;
  }
  .rbc-today {
    background-color: #fafdff;
    border: 2px solid #337ab7 !important;
    color: #337ab7 !important;
  }
  .rbc-current a {
    color: #337ab7 !important;
  }
  .rbc-date-cell > a,
  .rbc-date-cell > a:active,
  .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none;
    position: relative;
    right: 20px;
    top: 10px;
    font-size: 20px;
  }
  .rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    font-size: 16px;
  }
  .tooltip-inner {
    padding: 10px 5px !important;
    font-size: 14px !important;
  }
  .tooltip-inner .form-control-plaintext {
    padding: 3px !important;
    font-size: 13px !important;
    width: 100% !important;
  }
  .tooltip-inner .btn-primary {
    padding: 10px 10px !important;
    background: #337ab7 !important;
    font-size: 12px !important;
  }
  .rbc-row-segment {
    position: relative;
    top: 15px;
  }
  .tooltip {
    opacity: 1 !important;
    box-shadow: 0px 0px 7px lightgray !important;
    inset: -140px auto auto 0px !important;
  }
  .rbc-month {
    color: #145187;
    font-size: 26px;
    font-family: "HelveticaNeueCondensedBold", sans-serif;
  }
  .rbc-year {
    color: #145187;
    font-size: 26px;
    font-family: "HelveticaNeueCondensedBold", sans-serif;
  }
  .date1 button {
    border-radius: 50% !important;
    height: 20px !important;
    width: 20px !important;
    padding: 0 !important;
    border: 2px solid #c7c7c7 !important;
    position: relative;
    top: -2px; margin:0 .5rem !important;
  }
  .date1 .prev-icon {
    color: #c7c7c7 !important;
    font-size: 20px !important;
    position: relative !important;
    top: -5px !important;
  }
  .date2 {
    margin-left: 25px !important;
  }

  .eventTypes p {
    font-size: 12px;
  }
  .eventTypes {text-align:right; margin:1.2rem 0 0;
    .eventList {display:inline-block;}
    svg, p {display:inline-block; margin-right:.5rem;}
  }
  .date1 .prebtn {
    margin-right: 14px;
  }
  .date1 .nxtbtn {
    margin-left: 14px;
  }
  .rbc-time-slot {
    font-weight: 600;
  }
  .rbc-timeslot-group {
    border-bottom: 1px solid #ddd;
    min-height: 40px;
    display: flex;
    flex-flow: column nowrap;
    flex-direction: column-reverse;
  }

  .tooltipContent {
    z-index: 1590;
  }
  body .rbc-event {
    background-color: #d6e5f2;
  }
  .btn-calendar-meeting {
    text-align: center;
    font: normal normal 11px/13px "Helvetica Neue", sans-serif;
    letter-spacing: 0px;
    width: 120px !important;
    opacity: 1;
    background-color: #337ab7;
    border-radius: 3px;
  }
  .rbc-header {
    overflow: hidden;
    flex: 1 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 0 25px;
    text-align: center;
    vertical-align: middle;
    font-weight: bold;
    font-size: 90%;
    min-height: 0;
    border-bottom: 1px solid #ddd;
  }
  .rbc-month-view {
    position: relative;
    border: 1px solid #ddd;
    display: flex;
    flex-direction: column;
    flex: 1 0;
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
    height: 100%;
  }
  .rbc-calendar {
    box-sizing: border-box;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }

  .rbc-toolbar button {
    color: #373a3c;
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    background-image: none;
    border: 1px solid #ccc;
    padding: 0.375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap;
  }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid #ddd;
  }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 0px;
    top: 0;
  }
  .rbc-event-label {
    color: #145187;
    font-size: 12px;
    font-weight: 700;
  }
  .rbc-time-header.rbc-overflowing {
    margin-right: 0px !important;
  }
  .rbc-time-content {
    display: flex;
    flex: 1 0;
    align-items: flex-start;
    width: 100%;
    border-top: 2px solid #ddd;
    overflow-y: scroll;
    position: sticky !important;
  }

  div.select-search-container {
    z-index: 99;
  }
  .rbc-calendar .rbc-month-row {
    min-height: 7rem;
  }

  .btn-calendar-meeting a {
    color: #fff;
  }
  .hearingTitle {
    div {
      display: inline-block;
      margin-left: 1rem;
    }
    p {
      display: inline-block;
      margin-left: 15px;
    }
  }
  @media only screen and (max-width: 810px) {
    .rbc-toolbar {
      margin-bottom: 0;
    }
    .border.pmasec .input2 {
      width: 100% !important;
    }
  }

}
.disFlexDiv .hearingOfficer {font-weight:bold; display:inline-block; margin-right:1rem;}
.disFlexDiv .select-search-container {display:inline-block; position:relative; z-index:5;}

body .eventTypes {text-align:right; margin:0;}

@media only screen and (max-width:600px) {
  body .eventTypes {margin:0 0 1rem; text-align:center;}
  body .disFlexDiv {display:block;}
  body .disFlexDiv .hearingOfficer {width:100%; text-align:left; margin-bottom:.5rem;}
  body .disFlexDiv .select-search-container {width:100%;}
  .eventTypes {margin:0 0 1rem; text-align:center;}
  body .rbc-time-view {margin:0 0 2rem;}
  .rbc-time-header-content .rbc-header {font-size:55%;}
}

body .rbc-event {
  background-color: #d6e5f2; border:1px solid #3174ad;
}

.rbc-day-slot .rbc-events-container .rbc-event-label {color:#000; font-weight:500;}

.select-search-container{
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 3px !important;
  background-color: blue !important;
  pointer-events: none;
}
