.circleBase {
  border-radius: 50%;
}

.hold {
  width: 10px;
  height: 10px;
  background: #000;
  border: 1px solid #000;
  display: inline-flex;
  margin-right: 0.5rem;
}

.inprogress {
  width: 10px;
  height: 10px;
  background: #f27c47;
  border: 1px solid #000;
  display: inline-flex;
  margin-right: 0.5rem;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.MuiTableCell-root {
  border-bottom: 0 !important;
}
/*.hold {
  background: #000 !important;
  border: #000 !important;
}
.inprogress {
  background: #f27c47 !important;
  border: #f27c47 !important;
}*/

tr:nth-child(even) {
  background-color: #f8f8f8;
}

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  table.MuiTable-root
  tfoot.MuiTableFooter-root
  tr.MuiTableRow-root.MuiTableRow-footer
  td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1
  div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters
  div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.jss4 {
  /*display: none;*/
}

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  div.Component-horizontalScrollContainer-12
  div
  div
  div
  table.MuiTable-root
  thead.MuiTableHead-root
  tr.MuiTableRow-root.MuiTableRow-head
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  min-width: 120px !important;
}

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  table.MuiTable-root
  tfoot.MuiTableFooter-root
  tr.MuiTableRow-root.MuiTableRow-footer
  td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1
  div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.Component-paginationSelectRoot-4 {
  display: none !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
  background-color: #337ab7 !important;
  color: #fff !important;
}

.MuiTablePagination-spacer {
  display: flex !important;
  justify-content: center !important;
  flex: 0 0 0 !important;
}

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
  display: none;
}

.casesContainer {
  padding-left: 1%;
  padding-right: 1%;
}

/* .MuiTablePagination-input.MuiTablePagination-selectRoot { */
.MuiSelect-select.MuiSelect-select {
  border: 1.5px solid #e8e8e8;
}
/* } */

.topBar {
  display: flex;
  flex-direction: row;
}
.topBar p {
  margin: 0;
}

.leftContainer {
  display: flex;
  align-items: center;
  width: 35%;
}
.MuiSelect-icon {
  top: calc(50% - 12px);
}
.MuiFormControl-fullWidth {
  min-width: 25%;
  max-width: 25%;
}

.rightContainer {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  min-height: 1.1876em;
  justify-content: right;
  text-overflow: ellipsis;
  align-items: center;
  width: 65%;
}
.rightContainer p {
  margin: 0;
  font-size: small;
}

.MuiSvgIcon-root.MuiSelect-icon {
  display: none;
}
.SelectField {
  width:60px !important;
  background-color: #fff;
  border-radius: 0;
  border: 1px solid lightgray;
}
.controlDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
}
.perPagecounter {
  margin:0;
  display: inline-block;}
.perPagecounter p {display:inline-block; float:left;}
/* .showingResContainer{
    }
    .eventTypes{
    } */

.leftContainer.filterContainer {
  margin-left: 6px;
}
.colmn4 {
  width: 25%;
}
.colmn5 {
  width: 20%;
}

.MuiToolbar-root.MuiToolbar-regular.jss22.MuiToolbar-gutters
{
  display: none;
}

._loading_overlay_content {
  top: 20%;
  left: 45%;
  position: fixed;
}

.filtersbtn {text-align:left;}
.showDiv {display:flex; margin-bottom:15px;}

@media only screen and (max-width: 600px) {
  .filtersbtn {text-align:left;}
  .controlDiv {width:100%; margin:1rem 0 0;}
.showDiv {display:block; width:100%;}
.paginator {text-align:center; margin:1rem 0 0;}
.nav-fill .nav-item {width:7rem}
body .eventTypes {margin-bottom:.5rem;}
body .controlDiv {display:block; margin:0;}
.page {width:100%; float:none; text-align:center;}
.perPagecounter {margin:0 auto; float:none; width:135px;}
.showingResContainer {margin:.5rem 0; width:100%;}
.paginDiv {float:none;}
body .breadHeader .tab-content {padding:0;}
}

@media only screen and (max-width:480px) {
  .nav-fill .nav-item {width:100%;}
 
}