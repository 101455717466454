.App{text-align:center;}
:root{--backBluecol:#145187;--textBlueColor:#337ab7;}
@font-face{font-family:"HelveticaNeue";src:url("../src/assets/fonts/HelveticaNeueRegular.woff2") format("woff2"),url("../src/assets/fonts/HelveticaNeueRegular.woff") format("woff");font-weight:100;font-style:normal;font-display:swap;}
@font-face{font-family:"HelveticaNeueMedium";src:url("../src/assets/fonts/HelveticaNeue-Medium.woff2") format("woff2"),url("../src/assets/fonts/HelveticaNeue-Medium.woff") format("woff");font-weight:500;font-style:normal;font-display:swap;}
@font-face{font-family:"HelveticaNeueCondensedBold";src:url("../src/assets/fonts/HelveticaNeue-CondensedBold.woff2") format("woff2"),url("../src/assets/fonts/HelveticaNeue-CondensedBold.woff") format("woff");font-weight:bold;font-style:normal;font-display:swap;}
@font-face{font-family:"HelveticaNeueBold";src:url("../src/assets/fonts/HelveticaNeueBold.woff2") format("woff2"),url("../src/assets/fonts/HelveticaNeueBold.woff") format("woff");font-weight:bold;font-style:normal;font-display:swap;}
@font-face{font-family:"HelveticaNeueCondensedBlack";src:url("../src/assets/fonts/HelveticaNeue-CondensedBlack.woff2") format("woff2"),url("../src/assets/fonts/HelveticaNeue-CondensedBlack.woff") format("woff");font-weight:bold;font-style:normal;font-display:swap;}
.oneMinModal{z-index:99999;position:fixed;top:0;background-color:rgba(0,0,0,0.7);width:100%;height:100%;display:flex;justify-content:center;align-items:center;}
.innerModal{z-index:9999999;background-color:rgba(255,255,255,1);padding:10px;border-radius:5px;}
body{background-color:#fff;}
.tab-content{background-color:#fff;}
.App-logo{height:40vmin;pointer-events:none;}
.MuiButton-root.MuiButton-containedPrimary{background-color:purple;}
.MuiButton-root.MuiButton-containedPrimary:hover{background-color:#ab60ab;}
@media (prefers-reduced-motion:no-preference){
  .App-logo{animation:App-logo-spin infinite 20s linear;}
}
.App-header{background-color:#282c34;min-height:100vh;display:flex;flex-direction:column;align-items:center;justify-content:center;font-size:calc(10px + 2vmin);color:white;}
.App-link{color:#61dafb;}
@keyframes App-logo-spin{
  from{transform:rotate(0deg);}
  to{transform:rotate(360deg);}
}
.react-date-picker__wrapper .react-date-picker__inputGroup {
  min-width: 125px;  
}
span.custom--required {
  position: absolute;
  bottom: 1px;
}

td.MuiTableCell-root {
  /*position: relative;*/
    vertical-align: top;
    padding-top: 12px;
}


.main-container .alert{position:absolute;bottom:0;right:0;}
.my-custom-nav-bar .branding b{color:#fff!important;}
thead,thead tr,thead th,.MTableHeader-header-13{background:#ededed!important;}
.main-div{margin-bottom:20px;padding:0px 0px 0px 5px;}
.main-div .MuiSelect-icon{top:calc(50% - -5px)!important;}
.navbar.scrolling-navbar.top-nav-collapse,.my-custom-nav-bar{background-image:url(assets/img/loginBg.png);background-position:top;background-repeat:no-repeat;background-size:cover;background-color:#f1f1f1;}
.branding img{display:none;}
#navbarCollapse3{font-family:"HelveticaNeueCondensedBold",sans-serif;}
.navbar.scrolling-navbar .navbar-nav>li {transition-duration:inherit !important}
#navbarCollapse3 .navbar-nav.ml-auto a{color:black;font-size:16px;font-weight:normal; transition:none !important;}
.iconClass:hover{cursor:pointer;opacity:0.7;}
.MTableToolbar-searchField-10{border:1px solid #5a0480!important;border-radius:5px;}
.MTableToolbar-searchField-10 .MuiInput-underline:before{border:0!important;}
.navbar #navbarCollapse3 .dropdown-menu a{color:#000!important;font-size:14px;}
.MuiToolbar-gutters{padding-left:14px!important;}
.MuiToolbar-gutters h6{text-transform:capitalize!important;font-size:16px;}
.MuiTableCell-body{font-size:14px!important;}
.MuiToolbar-regular{min-height:44px!important;}
.MuiTableCell-body .MuiIcon-root{font-size:1.2rem;}
.singleMidBox{background:#fff;border-radius:5px;width:40%;margin:10% auto;min-height:150px;padding-top:50px;padding-bottom:50px;}
.main-container{height:100vh;padding:0 0;background:white;}
.forgotPage .form{padding:20px;}
#sdlc .MuiTable-root tr:nth-last-child(-n + 5){font-weight:bold;}
thead th,.MTableHeader-header-13{background-color:#ecf3f8!important;}
header .navbar-collapse .navbar-nav .active{position:relative;}
header .navbar-collapse .navbar-nav .active a:hover::before{color:#fff!important;}
header .navbar-collapse .navbar-nav .active::before{content:"";position:absolute;left:0;top:-22px;background:#337ab7;width:100%;height:228%;color:#fff!important;}
header .navbar-collapse .navbar-nav .active a{color:#fff!important;}
header .navbar-collapse .LogoutDiv .dropdown-toggle::after{position:absolute;right:4px;}
header .navbar-collapse .LogoutDiv .fa{background:#145187;padding:2px 4px 4px;text-align:center;line-height:1.3rem;border-radius:50px;width:25px;height:25px;color:#fff;}
header .navbar-collapse .LogoutDiv .fa-user:before{color:#fff;font-size:0.75rem;}
button[type="submit"]{background:#145187!important;}
.tabBox{max-height:48vh;overflow-y:auto;margin-bottom:10px;border:1px solid #ccc;}
.custom--drawer{top:70px!important;}
.custom--drawer .close{font-family:Arial,sans-serif;font-weight:bold;font-size:1.2rem;}
.myProfile{text-align:left;padding:1rem 0;font-family:"HelveticaNeueCondensedBold",sans-serif;}
.myProfile h1{font-size:1.35rem;font-weight:bold;color:#145187;margin:0 1.5rem 1rem;}
.myProfile .profileName{background:#f4f4f4;padding:1.3rem 2.5rem 1.3rem;display:flex;}
.myProfile .profileName h5,.myProfile .profileName p{margin:0;font-size:1rem;}
.myProfile .profileName h5{font-size:1.2rem;font-family:Impact,Haettenschweiler,"Arial Narrow Bold",sans-serif;}
.myProfile .MuiSvgIcon-root{font-size:2.75rem;margin:0 0.5rem 0 0;}
.myProfile .formDiv{margin:1rem 1.5rem;}
.myProfile .formDiv .MuiFormControl-fullWidth{margin-left:0!important;margin-right:0!important;}
.myProfile .formDiv .success{background:#e7fae7;border:1px solid #cae8ca;font-size:11px;padding:2px 5px;margin:1rem 0 0;}
.myProfile .formDiv .MuiButton-root.MuiButton-containedPrimary{width:100%;background-color:#337ab7!important;margin:1rem 0 0;font-size:12px;font-weight:600;}
.tooltip,.tooltip-inner{background:#fff!important;color:#337ab7!important;}
.tooltip-inner button[type="button"]{background:#145187!important;color:#fff;width:95%;}
@media only screen and (min-width:1400px){
  .tabBox{max-height:60vh;overflow-y:auto;margin-bottom:10px;border:1px solid #ccc;}
}
.tabPanelDiv table.MuiTable-root th:first-child,.tabPanelDiv table.MuiTable-root td:first-child{display:none;}
.tabPanelDiv table.MuiTable-root button.MuiButtonBase-root{margin-left:20px;margin-right:-10px;}
.rdw-embedded-wrapper .rdw-embedded-modal-header-option{font-size:14px!important;}
.rdw-link-wrapper .rdw-link-modal{height:245px!important;}
.navbar.navbar-dark .navbar-nav .nav-item:hover .nav-link{color:#145187;}
.navbar{box-shadow:none!important;border-bottom:1px solid lightgrey;background:#fff;background-image:none!important;}
/*table tr:hover{background:#e9edf0!important;}*/
.nav-tabs{font:normal 16px "HelveticaNeueCondensedBlack",sans-serif;}
.btn{font:normal 20px "HelveticaNeueCondensedBlack",sans-serif;}
.contactDiv{text-align:left;}
.contactDiv h5{font:normal 0.75rem "HelveticaNeueMedium",sans-serif;margin:0 0 1rem;}
.tableresponsivemd {text-align:left;}
.contactDiv table{border:0px solid #d6d6d6;width:50%;}
.contactDiv table td{padding:0.5rem;background:#fff;border:0px solid #d6d6d6;font:normal normal 1rem "Helvetica Neue",sans-serif; text-align:left;}
.contactDiv table td:first-child{background:#ecf3f8;}
.Component-horizontalScrollContainer-12{max-height:65vh;}
.MTableHeader-header-13{z-index:0!important;}
select{visibility:visible!important;-webkit-appearance:listbox!important;}
.MuiTableSortLabel-iconDirectionAsc{transform:rotate(180deg);  /* display: contents !important; */font-size:14px!important;color:gray!important;}

/* width */
::-webkit-scrollbar{width:8px;}
/* Track */
::-webkit-scrollbar-track{background:#f1f1f1;}
/* Handle */
::-webkit-scrollbar-thumb{background:#c7c7c7;}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover{background:#555;}
.profileMenu{display:none;}
body .rbc-toolbar{margin-top:1rem;}
@media only screen and (max-width:890px) {.contactDiv table {width:100%}}
@media only screen and (max-width:825px){
  body header .navbar.navbar-dark .navbar-nav .nav-item:nth-child(1) a{padding:10px 20px 0!important;}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(1):before{height:150%!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(2) a{padding:10px 20px 0!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(2):before{height:150%!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(3):before{height:128%}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(4) a{padding:10px 20px 0!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(4):before{height:150%!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(5):before{height:128%}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(6){display:none;}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(7) a{padding:12px 20px 12px 10px!important;border-left:1px solid #ccc}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(7)::before{height:150%!important}
}
@media only screen and (max-width:810px){
  .myProfile .formDiv .col-12{margin:0 0 .75rem;}
  .contactDiv table{width:100%;}
  body .topBar{display:block;}
  body .calendersec{width:100%;}
  body .leftContainer{width:100%;display:block;}
  body .leftContainer.filterContainer{width:100%;display:block;margin-bottom:1rem;text-align:left;}
  body .leftContainer.filterContainer p{display:inline-block;}
  body .rightContainer{width:100%;display:block;clear:both;text-align:left;margin-left:6px;}
  body .controlDiv{justify-content:left;}
  body .rbc-toolbar{display:block;margin-bottom:0;margin-top:1rem;}
  body .eventTypes{margin-bottom:1rem;}
  body .calendersec .rbc-calendar .rbc-btn-group{display:block;width:100%;margin:0 0 1rem!important;}
  .selectMonthYear,.genrateDiv{width:100%;margin-bottom:1rem;}
  .contactDiv{width:550px;}
}
@media only screen and (max-width:770px){
  body header .navbar.navbar-dark .navbar-nav .nav-item:nth-child(1) a{padding:10px 20px 0!important;}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(1):before{height:180%!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(2) a{padding:10px 20px 0!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(2):before{height:180%!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(3):before{height:128%}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(4) a{padding:10px 20px 0!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(4):before{height:180%!important}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(5):before{height:128%}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(6){display:none;}
  body header .navbar-collapse .navbar-nav .nav-item:nth-child(7) a{padding:12px 20px 12px 10px!important;border-left:1px solid #ccc}
}
.nav-tabs .nav-link:focus{border:0}
.modal-open .modal{z-index:9999;}
.rbc-month-view{min-height:100vh}
._loading_overlay_content_New{width:100%;height:100vh}
html, body,#root,.App, #app, #app>div {
  height: 100%
  }
  .btn.disabled-link {opacity:.5 !important;}