.invoices{
.file-submission {
    text-align: left;
    margin-left: 1rem;
    margin-bottom: 1rem;
  }
  .submissions {
    margin-top: 2rem;
    border: 2px solid #c7c7c7 !important;
    padding: 10px;
  }
  .file-submission-icon {
    color: #337ab7;
  }
  .casestructure .col-2{
    width: 10.666667% !important;
  }
  .nme{
    text-align: left;
      padding-top: 5px;
      padding-left:15px;
  }
  .nme2{
    color: black;
      font-size: 13px;
      font-weight: 600; line-height:1.1;
      display:block;  margin:0 0 .5rem;
  }
  .link{
    color: var(--bs-link-color);
    text-decoration: underline;
  }
  .linkborder{
    border: 1px solid #c7c7c7;
    margin: 0px 10px;
    padding: 13px;
    border-radius: 4px;
  }
  .linkhead{
    height: 15px;
  }
  .linkbody{
    text-align: left;
    margin-left: 1rem;
    margin-bottom: 10px !important;
    background: beige;
    min-height: 40px;
    padding: 5px 10px;
    border-radius: 5px;
  }
  select{
    border: 1px solid lightgray !important;
    border-radius: 3px !important;
    height: 32px;
    color: gray;
    margin: 0px 15px;
}
 label.hearingOfficer{
    font-family: HelveticaNeueCondensedBold, sans-serif;
    font-size: 16px;
    margin-top: 15px;
    margin-right: 10px;
}

.accordion-button{
  padding-right: 40px !important;
  margin-left:0!important;
  margin-right:auto;
}
}

@media only screen and (max-width:800px) {.invoices .submissions {overflow:hidden;}}