.card {
  box-shadow: none !important;
  border: 2px solid #c7c7c7 !important;
  border-radius: 2px !important;
}

div.timesheet-datagridentries th:nth-child(2)::before,
div.timesheet-datagridentries th:nth-child(3)::before,
div.timesheet-datagridentries th:nth-child(6)::before{
  color: red;
  content: '*';
}
div.timesheet-datagridentries th:nth-child(2)::after,
div.timesheet-datagridentries th:nth-child(3)::after,
div.timesheet-datagridentries th:nth-child(6)::after{
  color: red;
  content: ' [Required]';
}

.MTableHeader-header-13 {
  cursor: pointer !important;
}
.additionalbtn {
  background-color: white !important;
  box-shadow: none;
  border: 2px solid #337ab7;
  color: #337ab7;
  font-size: 11px;
  font-weight: 700;
  padding: 5px 15px;
}
.time .additionalbtn2 {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 35px;
  font-size: 14px;
  min-width: 360px;
  color: white;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}
.time {
  padding: 0.5rem 0rem;
  margin-bottom: 2rem;
}
.react-datetime-picker {
  display: inline-flex;
  position: relative;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  width: 95%;
}
.react-datetime-picker__wrapper {
  border: none;
}
.SelectField {
  background-color: #fff;
  border-radius: 3px !important;
  border: 1px solid lightgray !important;
  width: 65% !important;
  padding: 4px !important;
}
.lb {
  font-size: 13px;
  padding-left: 5px;
}
.form-check-input[type="radio"] {
  position: relative;
  top: 3px;
}
.additionalbtn:hover,
.additionalbtn:focus,
.additionalbtn:active {
  background-color: white !important;
  box-shadow: none;
  border: 2px solid #337ab7;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 3px !important;
  border: 1px solid lightgray !important;
}
.react-time-picker {
  width: 100%;
}

.timesheetSec {
  .font-weight-bold {
    font: normal 20px HelveticaNeueBold, sans-serif;
  }
}

.fi-file-uploader-wrapper .empty-files {
  text-align: center;
  padding: 15px 0;
  height: 65px;
  display: inline-flex;
  vertical-align: middle;
}

.ant-picker {
  border: 1px solid lightgray !important;
  border-radius: 3px !important;
  width: 130px !important;
}
.times .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid lightgray !important;
  border-radius: 3px !important;
}
.react-date-picker {
  width: 102%;
}
.times .note {
  border: 1px solid lightgray !important;
  border-radius: 3px !important;
  width: 100%;
}
div.text-start > button.additionalbtn2.btn.btn-primary {
  text-transform: unset !important;
}
input[type="radio"] {
  color: black;
}
.css-9mgopn-MuiDivider-root {
  margin: 0;
  /*-webkit-flex-shrink: 0;*/
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: gray !important;
  color: gray !important;
  border: 1px solid !important;
}
hr.divider-timesheet {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

div.border.timesheet.time {
  border-top: 0px solid #c7c7c7 !important;
  border-bottom: 2px solid #c7c7c7 !important;
  border-left: 2px solid #c7c7c7 !important;
  border-right: 2px solid #c7c7c7 !important;
}

.timesheetCard {
  border-bottom: 2px solid #c7c7c7 !important;
  border-top: 2px solid #c7c7c7 !important;
  border-left: 2px solid #c7c7c7 !important;
  border-right: 2px solid #c7c7c7 !important;
}
.modal-content .btn {
  background-color: #337ab7 !important;
  font-size: 13px;
  box-shadow: none;
  font-family: HelveticaNeueCondensedBold, sans-serif;
  padding: 5px 20px;
  border-radius: 3px;
}
.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-family: HelveticaNeueCondensedBold, sans-serif;
  font-size: 20px;
}

.ant-picker-panel-container {
  height: 300px;
}

.casestructure .col-2 {
  width: 10.666667% !important;
}
.nme {
  text-align: left;
  padding-top: 5px;
  padding-left:15px;
}
.nme2 {
  color: black;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.1;
  display: block;
  margin: 0 0 0.5rem;
}
.hearingDiv input[type="text"] {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  height: 33px;
}
.hearingDiv .react-time-picker,
.hearingDiv .react-date-picker {
  height: 33px;
}

@media only screen and (max-width: 600px) {
  .time .additionalbtn2 {
    min-width: 97%;
  }
}
.col-md-3 {
  width: 15%;
}
.datagrid {
  padding-top: 45px;
}

.required {
  color: red;
}
.main-div .Component-horizontalScrollContainer-12 {
  overflow-y: initial !important;
}
.datagrid1 .MuiTable-root .MuiTableFooter-root {
  min-height: 60px;
  display: inline-block !important;
  width: 100%;
  .MuiTableRow-root.MuiTableRow-footer {
    width: 100%;
    display: inline-block;
  }
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
    display: block !important;
    width: 100%;
  }

  .MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.Component-paginationSelectRoot-4 {
    display: inline-block !important;
    float: left;
    margin-right: 1rem;
  }
  .Component-paginationCaption-3 {
    display: inline-block !important;
    float: left;
    margin: 0.5rem 0.5rem 0 0;
  }
  .MuiTablePagination-selectRoot .MuiSelect-select.MuiSelect-select {
    padding: 0.5rem 0 !important;
  }
  .MTablePaginationInner-root-19 {
    display: inline-block;
    margin: -0.35rem 0 0;
    float: right;
  }
}

.dataTable .MuiPaper-root {
  z-index: 0;
}
.dataTable .MuiTableRow-root {
  height: auto !important;
}
.dataTable .MuiTable-root {min-height:140px}

.timesheetButton {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 35px;
  font-size: 14px;
  /* min-width: 293px; */
}
.response-submission .action.disabled-icon{
  cursor: default;
}
.response-submission .first-child
{
margin-left: 0 !important;
}

.reviewCard
{
  border-top: 0px !important;
}
.datagrid1.dataTable{
  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters
  {display: none !important;text-align:end;}
  .main-div .MuiFormControl-root.MuiTextField-root {min-width:auto; max-width:100%; padding:0;}
  .main-div .MuiInputAdornment-root , .main-div input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd{display:none !important;}
  .main-div .MuiFormControl-root.MuiTextField-root .react-date-picker {width:175px;}
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd
  {display: none !important;}
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters{display: block !important;text-align:end}
  
}
.datagridentries.dataTable{
  .MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters
  {display: block !important;text-align:end;}
  .main-div .MuiFormControl-root.MuiTextField-root {min-width:auto; max-width:100%; padding:0;}
  .main-div .MuiInputAdornment-root , .main-div input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd{display:none !important;}
  .main-div .MuiFormControl-root.MuiTextField-root .react-date-picker {width:175px;}
  .MuiIconButton-root:hover {
    background-color: rgba(0, 0, 0, 0);
  }
  input.MuiInputBase-input.MuiInput-input.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd
  {display: none !important;}
  .MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters{display: block !important;text-align:end}
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone,
  th.MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingNone
  {display: none !important;}
  td.MuiTableCell-root.MuiTableCell-body.MuiTableCell-paddingNone.MuiTableCell-sizeSmall
  {display:table-cell !important;}
  div {overflow-x:unset !important;}
}
.alertMsg{color: red;}
.main-div .MuiFormControl-root.MuiTextField-root {min-width:auto; max-width:100%; padding:0;}
.main-div .MuiFormControl-root.MuiTextField-root .MuiInputAdornment-root {display:none;}
.main-div .MuiFormControl-root.MuiTextField-root .react-date-picker {width:175px;}
.main-div .MTableToolbar-actions-8 {border:0px solid red !important; text-align:right;}

.datagridentries.dataTable {
  .commercial-timesheet-duration {
    input.MuiInputBase-input.MuiInput-input {
      padding-left: 5px;
    }
  }
  
  // .timesheet-datagridentries .MuiTable-root tr td:first-child
  // {
  //     display: none !important;
  // }
}