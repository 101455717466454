iframe {
  width: 98%;
}
.leftt {
  color: #8e8e8e;
  font-size: 13px;
  font-weight: 600;
  padding:0; margin:0;
}
.rightt {
  color: black;
  font-size: 13px;
  font-weight: 600; padding:0 0 .75rem;
}
.Schdule .btn {
  width: 67%;
  background: #337ab7 0% 0% no-repeat padding-box !important;
  color: #fff;
  font-size: 12px;
  padding: 8px;
  text-transform: capitalize;
  font-weight: 600;
  border-radius: 4px;
  position: relative;
  left: -2%;
  opacity: 1;
}
.Schdule .mb-1 {
  margin-bottom: -0.5rem !important;
}
.schdulebtn {
  margin-top: 1rem;
}
.disabled-link {
  pointer-events: none;
}
@media only screen and (max-width:767px) {
.pad0 {padding-left:0;}
}
@media only screen and (max-width:400px) {
.pdfDiv iframe {height:64vh !important;}
}