.loginPage {
  height: 100vh; width:100%; display:table;
  .MuiGrid-container {
    display: table-cell;
    vertical-align: middle;
  }
  /* PAGE HEADER CSS  */
  .mainLogo {position: absolute; top:20px; right: 60px; width: 140px; display: block;}
  .grey-text {color: black !important; font-weight: 600; font-family:HelveticaNeueCondensedBold, sans-serif;}
  .navbar.scrolling-navbar {padding: 30px 50px;background: none!important; box-shadow: none}
  #navbarCollapse3 {display: none!important}
  .SigninContainer form > div i.fa {
    position: absolute;
    right: 11px;
    top: 43px;
    z-index: 999;
    cursor: pointer;
}

.SigninContainer form > div {
    position: relative;
}
  /* PAGE BODY CSS  */  
  .SigninContainer{height: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center;}
  .loginBox .MuiPaper-root a {color:#145187; font-family: HelveticaNeueCondensedBold, sans-serif;}
  .loginBox .MuiPaper-root {
    box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16), 0 2px 10px 0 rgba(0,0,0,0.12); 
    width: 380px;
    height: 525px;
    
   text-align: left; background-color:#F5F5F5; 
    color: #333333; position: relative; margin: auto;  border-radius: 10px;}
    .container{padding:40px 40px 10px;}
    .loginheadbox{background-color: #fff;padding: 20px 40px 1px;border-radius: 10px 10px 0 0;text-align: center;
      .font-weight-bold {font:normal normal normal 18px/20px 'Helvetica Neue', sans-serif; font-weight:normal;}
    }
    .loginBox .MuiPaper-root .md-form label {display: none}
    .form-display-info {margin-bottom:25px}
    .form-group {margin-bottom:25px}
    .form-title {text-align: left; font-weight: bold}
    .contentBox  span {color: #fff; text-align: left; display: block; font-size: 30px; padding-right: 50%}
    span.text-danger {font-size: 12px}
     h2 {font-size:62px; font-weight:bold; color: #fff; width: 40%; text-align: left; padding-top:40px}
    button.btn-primary {width: 100%;
      background: #337AB7 !important;
      color: #fff;
      font-size: 14px;
      padding: 8px;
      text-transform: capitalize;
      font-family: HelveticaNeueCondensedBold, sans-serif;
      margin-left: 0px;
button.btn.btn-secondary{
      font-weight: 600; font-family:HelveticaNeueCondensedBlack, sans-serif;}
    }
    .col-md-6 {width:49%;}
    .col-md-6 .form-group {margin: 0}
    .new-user, .forgot-password {font-size: 12px; text-decoration: underline}
    .forgot-password {text-align: right}
    .form-check {position: relative; top:-2px}
    .form-check label {color:#333333; font-size: 12px}
    .new-user:hover, .forgot-password:hover {text-decoration: none}
    .copyRight {color: #fff; position: absolute; bottom: -50px; left:20px; font-size: 12px}
    .md-form.md-outline input {background: #f2f2f2!important}
    .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError {background: #fff!important; color: #000; padding: 0px 10px !important; width: 300px!important;height: 44px}
    .MuiAlert-icon {
      display: inline-block;
      opacity: 0.9;
      padding: 0px 0;
      font-size: 25px;
      margin-right: 12px;
      svg {color:red;}
  }
    .MuiSnackbar-anchorOriginBottomCenter .MuiAlert-standardError .MuiAlert-message {color: #000}
    .MuiSnackbar-anchorOriginBottomCenter {position:absolute !important; top:10px!important; align-items: baseline}
    .MuiPaper-root {align-self: baseline; align-items: baseline}  
  }
  
.loginheadbox img{
 width: 280px;
 margin-bottom: 5px;
}
.loginheadbox{
  font-size: 15px;
}
.form-control.is-invalid {
  background-position: right calc(1.8em + 0.1875rem) center !important;
}
.loginPage .fa-eye, .fa-eye-slash {
  position: absolute;
  top: 2.2rem !important;
  right: 3%;
}
label {
  margin-bottom: 0rem !important;
}
.icon-input-wrap, [input-type=text] {
  padding: 0 0 0 0px !important;
}
@media only screen and (max-width:810px) {
  .loginBox .MuiPaper-root {
    width: 100%;
  }
  .loginPage {
    padding: 0;
  }
  .loginPage .form-check {text-align:left;}
  .loginPage button.btn-primary {margin:.5rem 0 0;}
  .loginPage .loginBox .MuiPaper-root {height:525px;}
  .container.footer{margin:-1.5rem auto 0 !important}
  .loginPage form .row:last-child div.form-group{
    min-height: auto;
  }
  .loginPage .SigninContainer{
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
@media only screen and (max-width:700px) {
 
body .loginPage .loginBox .MuiPaper-root {width:90%;}
}
.container.footer {
  border-radius: 10px 10px 0 0;
  text-align: center;
  width: 290px !important;
  margin: -5.5rem auto 0;
  position: inherit;
}
.modal-body
{
  .casesContainer
{margin-top: 0px !important;}
}
// input::-ms-reveal,
// input::-ms-clear {
//   display: none;
// }
body .loginPage.errorInvalid .MuiSnackbar-anchorOriginBottomCenter {top:8.5rem !important;bottom:auto;}
body .loginPage.errorInvalid .MuiPaper-root {height:auto;}
//body .loginPage.errorInvalid .MuiSnackbar-anchorOriginBottomCenter {top:-177px !important}
body .loginPage.errorInvalid .MuiAlert-message {font-size:12px; font-weight: bold; position:relative; top:-8px; padding:0;}

body .loginPage .loginBox {position:relative;}