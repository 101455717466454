.circleBase {
  border-radius: 50%;
}

.disabled-link {
  pointer-events: none;
}

.hold {
  width: 10px;
  height: 10px;
  border: 1px solid #000;
  display: inline-flex;
  margin-right: 0.5rem;
  background: #000 !important;
}

.inprogress {
  width: 10px;
  height: 10px;
  background: #f27c47;
  border: 1px solid #000;
  display: inline-flex;
  margin-right: 0.5rem;
}

.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.MuiTableCell-root {
  border-bottom: 0 !important;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

/* html body div#root div.App div.main-container div.casesContainer div.main-div div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded table.MuiTable-root tfoot.MuiTableFooter-root tr.MuiTableRow-root.MuiTableRow-footer td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1 div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.jss4{
    /*display: none;
} 
*/
html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  div.Component-horizontalScrollContainer-12
  div
  div
  div
  table.MuiTable-root
  thead.MuiTableHead-root
  tr.MuiTableRow-root.MuiTableRow-head
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  min-width: 120px !important;
}

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  table.MuiTable-root
  tfoot.MuiTableFooter-root
  tr.MuiTableRow-root.MuiTableRow-footer
  td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1
  div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.Component-paginationSelectRoot-4 {
  display: none !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
  background-color: #337ab7 !important;
  color: #fff !important;
}

.MuiTablePagination-spacer {
  display: flex !important;
  justify-content: center !important;
  flex: 0 0 0 !important;
}

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
  display: none;
}

.casesContainer {
  padding-left: 1%;
  padding-right: 1%;
}

/* .MuiTablePagination-input.MuiTablePagination-selectRoot { */
.MuiSelect-select.MuiSelect-select {
  border: 1.5px solid #e8e8e8;
}
/* } */

.topBar {
  display: flex;
  flex-direction: row;
}
.topBar p {
  margin: 0;
}

.leftContainer {
  display: flex;
  align-items: center;
  width: 35%;
}
.MuiSelect-icon {
  top: calc(50% - 12px);
}
.MuiFormControl-fullWidth {
  min-width: 25%;
  max-width: 25%;
}

.rightContainer {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  min-height: 1.1876em;
  justify-content: right;
  text-overflow: ellipsis;
  align-items: center;
  width: 65%;
}
.rightContainer p {
  margin: 0;
  font-size: small;
}

.MuiSvgIcon-root.MuiSelect-icon {
  display: none;
}
.SelectField {
  background-color: #fff;
  border-radius: 0;
  border: 1px solid lightgray;
  width: 30% !important;
  padding: 4px !important;
}
.customdates {
  width: 30% !important;
  padding: 4px !important;
  margin-left: 5%;
  border-radius: 0;
}
.controlDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
}
.leftContainer.filterContainer {
  margin-left: 6px;
}
.colmn3 {
  width: 35%;
}
.colmn8 {
  width: 7.5%;
}

.colmn7 {
  width: 13.5%;
}

.colmn6 {
  width: 15.66%;
}

.react-date-picker__calendar {
  z-index: 99 !important;
}
.main-div .Component-horizontalScrollContainer-12 {
  overflow-y: hidden !important;
}
.dataTable .Component-horizontalScrollContainer-12 {
  height: auto !important;
}
.dataTable .Component-horizontalScrollContainer-12 .MuiTableCell-root .form-control {margin-bottom:.75rem;}
.dataTable .Component-horizontalScrollContainer-12 .MuiTableCell-root .alertMsg {position:absolute; left:-8px; top:-4px; color:red}
.dataTable .MuiTableRow-root {height:auto !important;}
.dataTable .MuiTable-root {min-height:140px}
.dataTable .Component-horizontalScrollContainer-12>div>div {
  overflow-y: unset !important;
}

.jss12>div>div{
  overflow-y: unset !important;
}
.jss12 {
  overflow-x: unset !important;
  height: auto !important;
}
.displaytimezone {
  font-size: small;
  text-align: left;
  margin: 0;
}

@media only screen and (max-width: 767px) {
  .jss12 > div > div {
    overflow-y: visible !important;
  }
  .jss12 {
    overflow-x: auto !important;
    height: auto !important;
  }
}

@media only screen and (max-width:600px) {
  .dataTable .colmn6 {width:50%; margin-bottom:1rem;}
}
