
.additionalbtn2 {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 35px;
  font-size: 14px;
  min-width: 360px;
  color: white;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}

.order-upload-data {
  .upload-form .order-upload-form {
    margin: 0;
  }

  .upload-form {
    text-align: left !important;
  }

  .custom-label {
    white-space: nowrap
  }
#fieldresolutionlabel0::after {
  color: red;
  content: '*';
  margin-left:2px;
}
#fieldvenue_state0::after {
  color: red;
  content: '*';
  margin-left:2px;
}
#fieldvenue_county0::after {
  color: red;
  content: '*';
  margin-left:2px;
}
#fieldgender_label0::after {
  color: red;
  content: '*';
  margin-left:2px;
}
#fieldage_label0::after {
  color: red;
  content: '*';
  margin-left:2px;
}


  .custom-required {
    color: red;
    font-size: 18px;
  }

  .fieldset-title {
    font-weight: 600;
    font-size: 15px;
    border-bottom: 1px solid #ccc;
    margin: 20px 0;
    width: 100%;
    background: #dae9f5;
    color: #000000;
    padding: 10px 0 10px 20px;
    margin-top: 0;

  }

  .fieldset-title em {
    color: #b1b1b1;
    font-style: normal;
    margin-right: 10px;
    display: inline-block;
  }

  .fieldset-title span {
    font-size: 12px;
    display: inline-block;
    margin-left: 10px;
  }

  .fi-file-uploader-wrapper {
    background: #eceff0;
    border: 1px solid #008fd4;
  }

  .file-drop>.file-drop-target {
    top: 20%;
  }

  .md-form.md-outline input[type="email"] {
    height: 44px;
    border-radius: 0;
  }

  /*.md-form.md-outline,*/
  .md-form.md-outline input,
  select.form-control {
    margin: 0;
    border-radius: 0;
  }



  .fi-file-uploader-wrapper .file-drop {
    border: 0;
  }

  .pageTitle {
    margin-bottom: 20px;
  }

  .control-shipping_info_zip_ext,
  .control-shipping_info_ship_complete {
    margin-left: -23px;
  }

  .control-shipping_info_ship_complete select {
    padding-left: 5px;
    padding-right: 0;
  }

  .form-actions {
    padding: 10px 0;
    margin: 0 -15px;
  }

  .control-deliver_info_delivery_date label.active.disabled {
    position: relative;
    top: -25px;
  }

  .form-actions .btn-default,
  .form-actions .btn-primary {
    min-width: 170px;
  }

  .container.order-upload-form {
    width: 100%;
    max-width: 100%;
  }

  .drop-title strong {
    background: #008fd4;
    font-size: 12px;
    text-transform: uppercase;
  }

  h3.form-title,
  label[for="order_info_dealer"],
  label[for="shipping"],
  label[for="shipping_info_ship_complete"],
  label[for="deliver_info_delivery_instructions"],
  label[for="performance_program_code"],
  label[for="shipping_info_state"] {
    display: none;
  }

  .empty-files,
  .fi-file-uploader-wrapper {
    padding: 0 !important;
    margin: 0 0 0 0;
  }


  .fi-file-uploader-wrapper .col-md-12 {
    padding: 0;
  }

  #fi-file-uploader-wrapper .file-drop {
    height: 97px;
  }


  .delivery-information .control1:nth-child(1) label.active.disabled {
    display: none;
  }

  .row.delivery-information .row:first-child .control1 label.active {
    top: 18px;
  }
}

.disableddiv {
  pointer-events: none;
  opacity: 0.4;
}

.smallPopupHeader.custom1 {
  height: auto !important;
}

.control-deliver_info_delivery_date .MuiFormControl-marginNormal {
  margin: 0;

  .MuiOutlinedInput-adornedEnd {
    border-radius: 0;
    width: 100%;
  }

  .MuiOutlinedInput-input {
    padding: 14.5px 14px;
  }
}

.MuiPickersBasePicker-container .MuiTypography-root {
  background: none;
}

.MuiPickersBasePicker-container .MuiToolbar-regular {
  display: block !important;
}

// label.custom-label {
//   padding-top: 28px !important;
// }

.demobtn {
  background: white;
  color: #337ab7;
  border: 2px solid #337ab7;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 35px;
  border-radius: 4px;
}

.leftt {
  color: #8e8e8e;
  font-size: 13px;
  font-weight: 600;
  padding:0; margin:0;
}

.rightt {
  color: black;
  font-size: 13px;
  font-weight: 600;
}
.form-btn .btn-primary {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 5px 35px;
  font-size: 14px;
  margin-right: 0px;
 
}
.form-actions .btn-primary {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 5px 35px;
  font-size: 14px;
  min-width: 300px !important;
}

button.btn-default.btn.Ripple-parent.btn.btn-primary.disabled {
  background-color: grey !important;
}

#fieldliab_split2 {
  color: #c7c7c7;
  padding-top: 6px;
}

.order-upload-data .md-form.md-outline,
.order-upload-data .md-form.md-outline input,
.order-upload-data select.form-control {
  margin: 0;
  border-radius: 0;
  border-radius: 4px;
}



#fieldcontribution2,
#fieldsecondary_contribution2,
#fieldhash2,
#fieldinje2,
#fieldtertiary_contribution2 {
  color: #c7c7c7;
  padding-top: 6px;
}

input#damagedate {
  border: none !important;
  padding-left: 12px !important;
}


.circle {
  border-radius: 50%;
  width: 5px;
  height: 5px;
  background: #000;
  position: relative;
  top: -3px;
  left: -14px;
}

.control-split .custom-control {
  display: inline-block !important;
}

.control-splitRadio .custom-control {
  display: inline-block !important;
}
.control-splitRadio .custom-radio{
  min-height: 1.5rem !important;
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important
}

div.form-group.control-splitRadio {
  margin-bottom: 0;
}

.control-split .custom-control-label {
  margin-right: 1rem;
}

div.form-group {
  min-height: 55px;

  label.grey-text {
    display: block;
    margin-bottom: 0;
  }

  div.form-group {
    margin: 0;
    min-height: auto;
  }
}

div.form-group.control-damageDate {
  margin: 0;
}

div.form-group.control-liab_splitlabel_radio,
div.form-group.control-split_radio {
  min-height: auto;
  margin-bottom: 1rem;
}

div.form-group.control-splitNote {
  margin-bottom: 2rem;
}

div.form-group.control-liab_splitlabel,
div.form-group.control-split,
div.form-group.control-liab_split {
  margin-bottom: 0;
}

body .MuiFormControl-marginNormal {
  margin: 4px 0 0 !important;
  height: auto;

  .MuiInputLabel-formControl {
    top: -12px;
  }

  .MuiOutlinedInput-input {
    padding: 8px 12px 8px;
  }
}

.order-information,
.primary-information,
.secondary-information-div,
.tertiary-information-div {
  border: 1px solid #C7C7C7;
  margin-bottom: 20px;
}

.tertiary-information-div .tertiary-information-div {
  border: 0
}

.demoborder {
  border: 0
}

.control-add_inury {
  min-height: auto !important;
  padding: 6px;
}

.addAnotherLabel {
  font: normal 14px HelveticaNeueMedium, sans-serif;
  background: none;
  border: 0;
  display: block;
  position: relative;
}

.addAnotherLabel::before {
  content: "\f055";
  display: inline-block;
  font: normal normal normal 17px/1 FontAwesome, sans-serif;
  margin-right: .5rem;
  color: #145187;
}

div.demogrpahic-split>span.text-danger {
  display: none;
}

.custom-control-input:checked~.custom-control-label::before {
  background-color: #000 !important;
  border-color: black !important;
}

#fieldadd_secondary_injury0 {
  min-height: auto;
}



.demographicdetails>select.form-control {
  -webkit-appearance: menulist !important;
  -moz-appearance: menulist !important;
  /*-ms-appearance: menulist !important;
  -o-appearance: menulist !important;*/
  appearance: menulist !important;
}

.control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {z-index:0; position: relative; left:-1rem; top:.4rem}
body .upload-form.demog .col-md-1 {width:0 !important; padding:0; margin:0;}

@media only screen and (max-width:950px) { 
  .order-upload-data .custom-label {white-space:normal;}
  .control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {left:0;}
  .order-information {margin-top:20px;}
  .timesheetSubmitDiv {margin-top:-54px !important}
}
@media only screen and (max-width:810px) {
  .order-upload-data .hash.custom-label, .order-upload-data  .inje.custom-label,
  .order-upload-data .contribution.custom-label, .order-upload-data .liab_split.custom-label,
  .order-upload-data .secondary_contribution.custom-label, .order-upload-data .tertiary_contribution.custom-label {white-space:normal; display:block; margin-top:-1rem; margin-bottom:1rem !important;}
  .form-group.control-split {margin-bottom:1rem !important;}
  .col-xs-8.col-md-4 {width:75%;}
  .col-xs-4.col-md-4 {width:25%; padding-top:1rem;}
  
}
@media only screen and (max-width: 600px) {
div.form-group {min-height:auto; margin-bottom:.5rem;}
div.form-group.control-damageDate {margin:0 0 .5rem;}
div.form-group.control-splitNote {margin-bottom:.5rem;}
.form-actions .btn-primary {min-width:calc(100% - 10px) !important}
.form-group.control-loss, .form-group.control-awarded, .form-group.control-limit {margin-top:0rem; width:calc(100% - 30px); margin-left:1.5rem;}
.control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {z-index:0; position: absolute; left:-21rem; top:2.2rem;}
#demographiccasedetails .demobtn2 {margin-top:2rem}
}
@media only screen and (max-width:435px) {
.control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {left:-23.5rem; top:2.2rem;}
}

@media only screen and (max-width:420px) {
  .control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {left:-22.5rem; top:2.2rem;}
  }

  @media only screen and (max-width:400px) {
    .control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {left:-19rem; top:2.2rem;}
    }

    
  @media only screen and (max-width:395px) {
    .control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {left:-21rem; top:2.2rem;}
    }

    @media only screen and (max-width:380px) {
      .control-eco_label_symbol, .control-amt_label_symbol, .policy_limit_symbol {left:-20rem; top:2.2rem;}
      }


// grammarly-editor-plugin {
//   --grammarly-default-border-radius: 0px  !important;
// }