.navbar-expand-md .navbar-collapse {
  display: flex;
  width: 100% !important;
}
.navbar-nav.ml-auto {
  width: 98%;
}
li {
  padding: 0;
}
.navbarCollapse3 {
  height: 100%;
}

header .navbar-collapse .navbar-nav .active {
  background-color: #337ab7 !important;
  height: 100% !important;
  margin-left: 5px;
}

.navbar.navbar-dark .breadcrumb .nav-item.active .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item.active .nav-link {
  color: #fff !important;
  background: transparent !important;
}
.custom--drawer.open {
  width: 360px;
  box-shadow: 0px 10px 16px #00000029;
  border: 1px solid #dddddd;
  opacity: 1;
}
html
  body
  div#root
  div.App
  div
  header
  nav.navbar-dark.fixed-top.scrolling-navbar.navbar.navbar-expand-md.my-custom-nav-bar {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.custom--drawer {
  position: fixed;
  right: 0px;
  top: 0 !important;
  bottom: 0px;
  width: 0px;
  background: #fff;
  z-index: 99999;
  border-left: 1px solid #ddd;
  /*box-shadow: "-2px 0px 6px 1px rgba(0 0 0 / 80%)";*/
  overflow: hidden;
  .close {
    margin-right: 40px !important;
    cursor: pointer;
  }
}

.notifDiv {
  margin-left: auto;
  border-right: 1px solid lightgray;
}

.dropdown-toggle.nav-link {
  display: flex !important;
  flex-direction: row-reverse;
  align-items: center;
  i {
    margin-right: 10px;
  }
}

.my-custom-nav-bar {
  background-color: #fff !important;
  color: #000;
  .branding {
    color: red;
    b {
      color: #000;
    }
  .logoBranding {display:block; width:110px; height:auto; margin-left:15px;}
  }
  .navbar-nav {
    .nav-item {
      color: #000;
      font-size: 14px;
    }
  }
  .nav-link.Ripple-parent.active {
    border-bottom: 2px solid #571c7c;
  }
  .nav-link.Ripple-parent {
    border-bottom: 2px solid transparent;
    font-weight: bold;
  }
}
.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #000 !important;
  padding: 0px 20px;
}

body .navbar.navbar-dark .breadcrumb .nav-tabs .nav-link:focus,
body .navbar.navbar-dark .navbar-nav .nav-item .nav-link:focus {border:0;}

.navbar.navbar-dark .breadcrumb .nav-item.active:hover .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item.active:hover .nav-link {
  color: #fff !important;
}

.navbar.navbar-dark .breadcrumb .nav-item:hover .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item:hover .nav-link {
  color: #337ab7 !important;
}
.branding {
  cursor: pointer;
}

.footerButtons {
  display: block !important;
}
.LogoutDiv {
  padding-right: 15px;
}
.fa-bell {
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 2px;
  -webkit-text-stroke-color: #145187;
}

.d-none {
  font-weight: 600;
}
.myProfile .profileName p {
  margin: 0;
  font-size: 12px;
  font-weight: 600;
  position: relative;
  top: 15px;
}
.myProfile label + .MuiInput-formControl {
  margin-top: 16px;
  width: 17.5rem;
}
.myProfile label {
  width: 20rem;
  color: #333333 !important;
  font-weight: 600 !important;
  line-height: 45px !important;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}
.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}
.MuiInput-underline:before {
  left: 0;
  right: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-bottom: none !important;
  pointer-events: none;
}
.MuiInputBase-input {
  font: inherit;
  color: currentColor;
  width: 100%;
  height: 1.1876em;
  margin: 0;
  display: block;
  padding: 6px 0 7px;
  min-width: 0;
  background: none;
  box-sizing: content-box;
  animation-name: mui-auto-fill-cancel;
  letter-spacing: inherit;
  animation-duration: 10ms;
  -webkit-tap-highlight-color: transparent;
  border: 2px solid #d1d1d1 !important;
  border-radius: 4px;
}

/*.navbar{
        padding: '0% 3%' !important;
}*/
.circleBase2 {
  border-radius: 50%;
  width: 14px;
  height: 14px;
  background: #d55d5d;
  color: #fff;
  text-align: center;
  font-size: 9px;
  position: relative;
  top: 5px;
  left: 5px;
}

.proemail {
  white-space: nowrap;
  width: 225px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.drawer-snackbar {
  right: 0px !important;
  width: 360px !important;
  margin-right: -0.5rem;
}
div.update-password
  > button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
  text-transform: unset !important;
}
@media only screen and (max-width:825px) {
  .circleBase2 {width:24px; height:24px; line-height:24px; top:4px}
}

@media only screen and (max-width:810px) {
  header .navbar-collapse .navbar-nav li.nav-item .dropdown .dropdown-toggle {padding-left:0;}
  header .navbar-collapse .navbar-nav li.nav-item.LogoutDiv {width:180px;}
  header .navbar-collapse .navbar-nav li.nav-item.LogoutDiv .d-md-inline {overflow:hidden; text-overflow:ellipsis;}
  .navbar-nav.ml-auto {display: flex; align-items: center; justify-content: center; width:96%;}
  header .navbar-collapse .navbar-nav li.nav-item:nth-child(3)::before,
  header .navbar-collapse .navbar-nav li.nav-item:nth-child(5)::before {height:144% !important}
}
@media only screen and (max-width: 600px) {
  header .navbar-collapse .navbar-nav .active {width:100%;}
  .my-custom-nav-bar .branding .logoBranding {width:95px; height:auto; margin-left:.5rem;}
  .LogoutDiv {
    display: none;
  }
  .navbar.navbar-dark .navbar-toggler {background:#000; padding:1px 2px; border-radius:0 !important; margin: 0 1rem 0 0;}
  body .navbar-expand-md .navbar-collapse {
    width: 90% !important;
    margin: 0 auto; display:none; 
  }
  body .navbar-expand-md .navbar-collapse.show {display:block;}
  #navbarCollapse3 .navbar-nav.ml-auto a {
    display: block !important;
  }
  header .navbar-collapse .navbar-nav .active::before {
    background: transparent;
  }
  .navbar.scrolling-navbar .navbar-nav > li.LogoutDiv {
    position: absolute;
    right: 4rem;
    top: 0.75rem;
  }
  header .navbar-collapse .LogoutDiv .dropdown-toggle::after {
    position: absolute;
    right: 4px;
    top: 0.6rem;
  }
  header .navbar-collapse .LogoutDiv .fa {
    background: #145187;
    padding: 2px 4px 4px;
    text-align: center;
    line-height: 1.3rem;
    border-radius: 50px;
    width: 25px;
    height: 25px;
    color: #fff;
  }
  header .navbar-collapse .LogoutDiv .fa-user:before {
    color: #fff;
    font-size: 0.75rem;
  }
  .profUser {
    position: relative;
    right: -0.5rem;
  }
  .profileMenu {
    display: block;
    position: fixed !important;
    top:-2px;
    right:2.5rem;
    z-index: 9999;
    color: #145187;
  }
  body .profileMenu .dropdown-toggle,
  body .profileMenu .dropdown-toggle:hover,
  body .profileMenu .dropdown-toggle:focus,
  body .show > .btn-primary.dropdown-toggle {
    background: transparent !important;
    border: none !important;
    box-shadow: none;
    color: #000;
    font-weight: bold;
    font-size:13px;
  }
  body .profileMenu .dropdown-toggle .MuiSvgIcon-root {margin-top:-4px;}
  body .profileMenu .btn-primary:not(:disabled):not(.disabled):active:focus, .profileMenu.show>.btn-primary.dropdown-toggle:focus {box-shadow:none;}
  body .dropdown-menu {
    padding: 0.5rem;
  }
  li.MuiButtonBase-root {
    display: block !important;
    width: 100%;
    padding: 0.5rem 0;
    color: #145187;
    font-weight: bold;
  }
  .MuiSvgIcon-root {
    color: #145187;
  }
}
@media only screen and (max-width:400px) {
  .profileMenu {right:2.7rem;}
  body .profileMenu .dropdown-toggle, body .profileMenu .dropdown-toggle:hover, body .profileMenu .dropdown-toggle:focus, body .show > .btn-primary.dropdown-toggle {font-size:11px !important;}
  .dropdown-menu li.MuiButtonBase-root {font-size:13px; padding:0; min-height:35px;}
}