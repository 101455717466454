.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.Component-paginationToolbar-2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
}

.MuiTableCell-root {
  border-bottom: 0 !important;
}
.hold {
  background: #000 !important;
  border: #000 !important;
}
.inprogress {
  background: #f27c47 !important;
  border: #f27c47 !important;
}

tr:nth-child(even) {
  background-color: #f8f8f8;
}

// html
//   body
//   div#root
//   div.App
//   div.main-container
//   div.casesContainer
//   div.main-div
//   div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
//   table.MuiTable-root
//   tfoot.MuiTableFooter-root
//   tr.MuiTableRow-root.MuiTableRow-footer
//   td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1
//   div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters
//   div.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.jss4 {
//   //display: none;
// }

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  div.Component-horizontalScrollContainer-12
  div
  div
  div
  table.MuiTable-root
  thead.MuiTableHead-root
  tr.MuiTableRow-root.MuiTableRow-head
  th.MuiTableCell-root.MuiTableCell-head.MTableHeader-header-13.MuiTableCell-alignLeft.MuiTableCell-sizeSmall {
  min-width: 120px !important;
}

html
  body
  div#root
  div.App
  div.main-container
  div.casesContainer
  div.main-div
  div.MuiPaper-root.MuiPaper-elevation2.MuiPaper-rounded
  table.MuiTable-root
  tfoot.MuiTableFooter-root
  tr.MuiTableRow-root.MuiTableRow-footer
  td.MuiTableCell-root.MuiTableCell-footer.MuiTablePagination-root.jss1
  div.MuiToolbar-root.MuiToolbar-regular.MuiTablePagination-toolbar.jss2.MuiToolbar-gutters {
  display: flex;
  justify-content: center;
  align-items: center;
}

.MuiInputBase-root.MuiTablePagination-input.MuiTablePagination-selectRoot.Component-paginationSelectRoot-4 {
  display: none !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedSizeSmall.MuiButton-sizeSmall.Mui-disabled.Mui-disabled {
  background-color: #337ab7 !important;
  color: #fff !important;
}

.MuiTablePagination-spacer {
  display: flex !important;
  justify-content: center !important;
  flex: 0 0 0 !important;
}

.MuiToolbar-root.MuiToolbar-regular.MTableToolbar-root-5.MuiToolbar-gutters {
  display: none;
}

.casesContainer {
  padding: 0 1.2rem;
  margin: 5rem 0 0;
  .MuiTablePagination-input.MuiTablePagination-selectRoot {
    .MuiSelect-select.MuiSelect-select {
      border: 1.5px solid #e8e8e8;
    }
  }
  .nav-tabs {
    font: normal 16px HelveticaNeueCondensedBold, sans-serif;
    .nav-item {
      .nav-link {
        color: #000;
        &:hover {
          border:1px solid #fff;
        }
      }
      .nav-link.active {
        border: none;
        border-bottom: 2px solid #337ab7;
        color: #337ab7;
      }
    }
  }
}

.topBar {
  display: flex;
  p {
    margin: 0;
  }
  flex-direction: row;
}

.leftContainer {
  display: flex;
  align-items: center;
  width: 35%;
  .MuiSelect-icon {
    top: calc(50% - 12px);
  }
  .MuiFormControl-fullWidth {
    min-width: 25%;
    max-width: 25%;
  }
}

.rightContainer {
  display: flex;
  white-space: nowrap;
  overflow: hidden;
  min-height: 1.1876em;
  justify-content: right;
  text-overflow: ellipsis;
  align-items: center;
  width: 100% !important;
  p {
    margin: 0;
    font-size: small;
  }
}
.MuiSvgIcon-root.MuiSelect-icon {
  display: none;
}
.SelectField.pageSize {
  width:55px !important;
  background-color: #fff;
  border-radius: 0 !important;
  border: 1px solid lightgray; height:36px;
}
.controlDiv {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-width: 100%;
 
  //   .showingResContainer {
  //   }
  //   .eventTypes {
  //   }
}
.showingResContainer {display:inline-block; margin-right:.5rem;}
.paginDiv {display:inline-block;}
.paginDiv .row {display:inline-block;}

/*BredCrum*/
ul.breadcrumb {
  padding: 0px 5px;
  list-style: none;
  li {
    display: inline;
    cursor: pointer;
    font: normal 11px HelveticaNeueMedium, sans-serif;
    a {
      color: #000;
      text-decoration: underline;
      &:hover {
        color: #01447e;
        text-decoration: underline;
      }
    }
  }
  li + li {
    &:before {
      padding: 8px;
      color: #000;
      content: " \003E";
    }
  }
}

.LoadingData {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
  background-color: rgba(236, 243, 248, 0.7);
  z-index: 9999;
  iframe {
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    align-items: center;
    overflow: visible;
  }
}

.dataTable {margin:1rem 0 0;
  .MuiInputAdornment-positionStart {
    margin-right: 0;
  }
  .MuiFormControl-root .MuiInputAdornment-root .material-icons {
    display: none;
  }
}
.dataTable.pendingTasks {
  .col-md-3 {width:calc(100% - 79.4%); margin-bottom:.5rem;}
  .col-md-3:nth-child(3) {width:calc(100% - 83%)}
}

.stickyDiv {
  position:relative;
  top: 0;
}

.breadHeader {
  position: relative;
  background: #fff;
  .breadcrumb {
    width: 98%;
    padding: 15px 5px 5px;
    margin: -2.2rem 0 0;
    display: block;
    line-height: 13px;
    text-align: left;
    min-height: 4rem;
  }
  .breadcrumb,
  .mb-3.nav-tabs {
    position: fixed;
    z-index: 4;
    background: #fff;
  }
  .mb-3.nav-tabs {
    width: 98%;
    top: 6.5rem;
    background: #fff;
  }
  .tab-content {
    margin: 5rem 0 0;
    padding: 5rem 0 0;
  }
}

thead,
thead tr,
thead th,
.MTableHeader-header-13 {
  background: #ededed !important;
}
/*table tr:hover {
  background: #e9edf0 !important;
}*/
#filterSelect {
  width: 40% !important;
}

.react-date-picker__calendar {
  z-index: 99 !important;
}

.filtersbtn .btn {
  background-color: #337ab7 !important;
  box-shadow: none;
  border-radius: 3px;
  font-size: 14px;
  font-family: HelveticaNeueCondensedBold, sans-serif;
  padding: 5px 20px;
  min-width: 115px;
  text-transform: capitalize;
}
.filtersbtn {
  text-align:left; padding-left:5px; margin:.7rem 0 0;
}
.dataTable .react-date-picker__button svg {
  display: inherit;
  width: 1.2vw;
}
.MuiToolbar-root.MuiToolbar-regular.MuiToolbar-gutters {
  display: none !important;
}

.pendingTasksTable {
  /*NAM ID Column*/
  .MuiTable-root tr th:nth-child(1),
  .MuiTable-root tr td:nth-child(1) {
    width: 0 !important;
    display: none !important;
  }

  .MuiTable-root tr th:nth-child(2),
  .MuiTable-root tr td:nth-child(2) {
    width: 6.25rem !important;
  }

  /*NAM Caption Column*/
  .MuiTable-root tr th:nth-child(3),
  .MuiTable-root tr td:nth-child(3) {
    width: 25.25rem !important;
    z-index: 1 !important;
  }

  .MuiTable-root tr th:nth-child(4),
  .MuiTable-root tr td:nth-child(4) {
    width: 7.75rem !important;
    white-space:nowrap;
  }

  .MuiTable-root tr th:nth-child(5),
  .MuiTable-root tr td:nth-child(5) {
    width: 12.25rem !important;
    white-space:nowrap;
  }

  .MuiTable-root tr th:nth-child(7),
  .MuiTable-root tr td:nth-child(7) {
    width: 10.5rem !important;
    white-space:nowrap;
  }
}






.casesContainer.pendingTasksTable.no-records
{
  .MuiTable-root tr th:first-child
  {
    display:none !important;
    }
    
  .MuiTable-root tr td:first-child
  ,  .MuiTable-root tr th:nth-child(0),  .MuiTable-root tr td:nth-child(0)
  {
  display:table-cell !important;
  }
  

  .Component-horizontalScrollContainer-12 .MuiTable-root tr td:first-child,
  .MuiTable-root tr th:nth-child(0),
  .MuiTable-root tr td:nth-child(0) {
    display: table-cell !important;
  }
}

.tooltip1 {
  position: relative;
  display: inline-block;
  // border-bottom: 1px dotted black;
}

.tooltip1 .tooltiptext {
  visibility: hidden;
  width: 220px;
  background-color: gray;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
}

.tooltip1:hover .tooltiptext {
  visibility: visible;
}
.dataTable .Component-horizontalScrollContainer-12 {
  height: auto !important;
}
.dataTable .Component-horizontalScrollContainer-12>div>div {
  overflow-y: unset !important;
}
.jss12>div>div{
  overflow-y: unset !important;
}
.jss12{
  overflow-x: unset !important;
  height: auto !important;
}

.eventTypes {
  .eventList {display:inline-block;}
  svg, p {display:inline-block; margin-right:.5rem;}
}

@media only screen and (max-width:1185px) {
  .dataTable.pendingTasks .col-md-3 {width:calc(100% - 82%); margin-bottom:1rem;}
  .dataTable.pendingTasks .col-md-3:nth-child(3) {width:calc(100% - 74%);}  
}
@media only screen and (max-width:825px) {
  .dataTable.pendingTasks .col-md-3 {width:calc(100% - 67%); margin-bottom:1rem;}
  .dataTable.pendingTasks .col-md-3:nth-child(3) {width:calc(100% - 66.7%);}
}

@media only screen and (max-width:810px) {
.dataTable.pendingTasks {
  .col-md-3 {width:calc(100% - 50%); margin-bottom:1rem;}
  .col-md-3:nth-child(3) {width:calc(100% - 50%)}
}
.jss12>div>div{
  overflow-y:visible !important;
}
.jss12{
  overflow-x: auto !important;
  height: auto !important;
}
} 

.dataTable.pendingTasks
{
  /* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

}

@media only screen and (max-width:600px) {
.dataTable.pendingTasks .colmn4 {width:50%; margin-bottom:1rem;}
.dataTable.pendingTasks .col-md-3.col-6 {width:100%}
}