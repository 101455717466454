.card {
  box-shadow: none !important;
  border: 2px solid #c7c7c7 !important;
  border-radius: 2px !important;
}
.additionalbtn {
  background-color: white !important;
  box-shadow: none;
  border: 2px solid #337ab7;
  color: #337ab7;
  font-size: 11px;
  font-weight: 700;
  padding: 5px 15px;
}
.time .additionalbtn2 {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 35px;
  font-size: 14px;
  min-width: 360px;
  color: white;
  font-family: HelveticaNeueCondensedBold, sans-serif;
}
.time {
  padding: 0.5rem 0rem;
  margin-bottom: 2rem;
}
.react-datetime-picker {
  display: inline-flex;
  position: relative;
  border: 2px solid #c7c7c7;
  border-radius: 3px;
  width: 95%;
}
.react-datetime-picker__wrapper {
  border: none;
}
.SelectField {
  background-color: #fff;
  border-radius: 3px !important;
  border: 1px solid lightgray !important;
  width: 65% !important;
  padding: 4px !important;
}
.lb {
  font-size: 13px;
  padding-left: 5px;
}
.form-check-input[type="radio"] {
  position: relative;
  top: 3px;
}
.additionalbtn:hover,
.additionalbtn:focus,
.additionalbtn:active {
  background-color: white !important;
  box-shadow: none;
  border: 2px solid #337ab7;
  font-size: 11px;
  font-weight: 700;
  cursor: pointer;
}
.react-time-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border-radius: 3px !important;
  border: 1px solid lightgray !important;
}
.react-time-picker {
  width: 100%;
}

.timesheetSec {
  .font-weight-bold {
    font: normal 20px HelveticaNeueBold, sans-serif;
  }
}

.fi-file-uploader-wrapper .empty-files {
  text-align: center;
  padding: 15px 0;
  height: 65px;
  display: inline-flex;
  vertical-align: middle;
}

.ant-picker {
  border: 1px solid lightgray !important;
  border-radius: 3px !important;
  width: 130px !important;
}
.times .react-date-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  border: 1px solid lightgray !important;
  border-radius: 3px !important;
}
.react-date-picker {
  width: 102%;
}
.times .note {
  border: 1px solid lightgray !important;
  border-radius: 3px !important;
  width: 100%;
}
div.text-start > button.additionalbtn2.btn.btn-primary {
  text-transform: unset !important; font:normal 14px HelveticaNeueMedium, sans-serif !important;
}
input[type="radio"] {
  color: black;
}
.css-9mgopn-MuiDivider-root {
  margin: 0;
  /*-webkit-flex-shrink: 0;*/
  -ms-flex-negative: 0;
  flex-shrink: 0;
  border-width: 0;
  border-style: solid;
  border-color: gray !important;
  color: gray !important;
  border: 1px solid !important;
}
hr.divider-timesheet {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: 1px solid;
  opacity: 0.25;
}

div.border.timesheet.time {
  border-top: 1px solid #c7c7c7 !important;
  border-bottom: 2px solid #c7c7c7 !important;
  border-left: 2px solid #c7c7c7 !important;
  border-right: 2px solid #c7c7c7 !important;
}

.timesheetCard {
  border-bottom: 2px solid #c7c7c7 !important;
  border-top: 2px solid #c7c7c7 !important;
  border-left: 2px solid #c7c7c7 !important;
  border-right: 2px solid #c7c7c7 !important;
}
.modal-content .btn {
  background-color: #337ab7 !important;
  font-size: 13px;
  box-shadow: none;
  font-family: HelveticaNeueCondensedBold, sans-serif;
  padding: 5px 20px;
  border-radius: 3px;
}
.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
  font-family: HelveticaNeueCondensedBold, sans-serif;
  font-size: 20px;
}

.ant-picker-panel-container {
  height: 300px;
}

.casestructure .col-2 {
  width: 10.666667% !important;
}
.nme {
  text-align: left;
  padding-top: 5px;
  padding-left:15px;
}
.nme2 {
  color: black;
  font-size: 13px;
  font-weight: 600;
  line-height: 1.1;
  display: block;
  margin: 0 0 0.5rem;
}
@media only screen and (max-width: 600px) {
  .time .additionalbtn2 {
    min-width: 97%;
  }
}

.tooltipCirelc {
  text-align: center;
  position: absolute !important;
  left: 7.3rem;
  top: 8px;
  font-weight: bold;
  width: 25px;
  height: 25px;
  padding: 0px;
  border: 1px solid rgb(204, 204, 204);
  border-radius: 50px;
  display: block; background:#ccc;
}
.noteexceeds{
  margin-top: 35%;
    margin-left: -25%;
    background-color: #cff4fc;
    color: #3a686e;
    text-align: left;
    padding: 4%;
}
.noteexceedsadditionalTimeSheet{
  margin-top: 53%;
    margin-left: -25%;
    background-color: #cff4fc;
    color: #3a686e;
    text-align: left;
    padding: 4%;
}
.displaytimezone {
  font-size: inherit;
  text-align: left;
  margin: 0;
}

.timesheetSubmitDiv {margin:-84px; margin-bottom:75px;}

.clearfix {clear:both; overflow:hidden; height:0; font-size:0; display:block;}
@media only screen and (max-width:600px) {
body .timesheetSubmitDiv {float:right; width:110px; margin:30px 30px 0 0 !important;}
.timesheetSubmitDiv .action-btns {padding:0 !important}
}
