.MuiDialog-root{
  z-index: 10000 !important;
} 
.leftt {
  color: #8e8e8e;
  font-size: 13px;
  font-weight: 600; margin:0; padding:0;
}
#focusInput, #signfocusInput{ width: 1px; height:1px; opacity: 0 !important;}
.noLaterField{
  position: relative;
  margin-top: 10px;
}
.amountInput span.custom--required{
  left: -8px;
  top: -8px;
}
.amountInput{
  position: relative;
  padding-left: 10px;
}
span.amountSymbol {
  position: absolute;
  left: 0px;
}
.noLaterField span.custom--required {
  top: -18px;
}
.rightt {
  color: black;
  font-size: 13px;
  font-weight: 600;
}
.pmabtn {
  background: white;
  color: #337ab7;
  border: 2px solid #337ab7;
  font-size: 14px;
  font-weight: 600;
  padding: 2px 35px;
  border-radius: 4px;
}
.pmabtn2 {
  background: #f7ecda;
  color: #333333;
  border: 1px solid #e6d4b6;
  font-size: 14px;
  padding: 2px 35px;
  border-radius: 4px;
}
.pmabtn3 {
  background: #e7fae7;
  color: #333333;
  border: 1px solid #cae8ca;
  font-size: 14px;
  padding: 2px 35px;
  border-radius: 4px; float:right;
}
.pmabtn4 {
  background: #e6ecf2;
  color: #333333;
  border: 1px solid #e6ecf2;
  font-size: 14px;
  padding: 2px 35px;
  border-radius: 4px;
}
.pmasec .input2 {
  border-top: none;
  border-right: none;
  border-left: none;
  border-bottom: 1px solid #c7c7c7;
  border-radius: 0;
}
.pmasec .input2notValidDefendent {
  border: 1px solid red;
}
.pmasec .input2notValidPlantiff {
  border: 1px solid red;
}
.pmasec input {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  height: 33px;
}
.pmasec input[type="checkbox"].chckbox {height:15px; margin:.5rem 0 0 -1.5rem; border:1px solid #333; padding:0;}
.pmasec textarea {
  border: 1px solid #c7c7c7;
  border-radius: 4px;
}
.pmasec {
  padding: 15px;
  line-height: 30px;
  font-size: 14px;
  border: 2px solid #c7c7c7 !important;
}
.pmaName{
  width: 96%;
}
.disableditem span.drop-title {
  opacity: .6;
}

.hide--required .custom--required{
  visibility: hidden;  
}
.hideerror input{border-color: #7c7c7c !important;}
.hideerror .error-message{ visibility: hidden !important;}
body .disabledBtn.additionalbtn2{
  border: 2px solid #b5babe;
  color: #b5babe;
}
.mtp20{
  margin-top: 20px;
}
.padLeft35{
  padding-left: 35px;
}
.dollerDiv input[type="text"] {width:calc(100% - 84%);}
.pmaEmail {
  border: 1px solid #c7c7c7 !important;
   border-radius: 4px;
   width: 100%;

}
label.pmaSignatoryCheckbox {
  min-width: 130px;
  text-align: center;
  display: inline-block;
}

.pmasec .pmaSignatoryCheckbox input {
  height: auto;
}
.pmaButton {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 35px;
  font-size: 14px;
  min-width: 360px;
}
.pmaButtonDisabled {
  background-color: #337ab7 !important;
  border: none;
  border-radius: 4px;
  font-weight: 600;
  padding: 2px 35px;
  font-size: 14px;
  min-width: 360px;
  opacity: 0.5;
}
.uploaded-files{
  margin-bottom: 15px !important;
}

.file-drop > .file-drop-target {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 2px;
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  align-items: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  align-content: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  text-align: center;
  background-color: #f4f4f4;
}
.mb-1s {
  margin-bottom: -0.5rem;
}
iframe {
  width: 100%;
}
.fi-file-uploader-wrapper .file-drop {
  border: 2px solid;
  border-style: none;
  border-color: #cccccc;
}
.border {
  border-top: 2px solid #c7c7c7 !important;
  border-left: 2px solid #c7c7c7 !important;
  border-right: 2px solid #c7c7c7 !important;
}
.Exclude {
  background: #fff;
  color: #9a9a9a;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  margin-left: 5px;
}
.ExcludeDisabled {
  background: #c7c7c7;
  color: black;
  border: 1px solid #c7c7c7;
  border-radius: 4px;
  margin-left: 5px;
  opacity: 0.5;
}
.fa-exclamation-circle {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome, sans-serif;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #c18d3a;
  padding-right: 7px;
}
.fa-check-circle {
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome, sans-serif;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #6fb76f;
  padding-right: 7px;
}
.drop-title {cursor: pointer;}
.dropSubTitle {
  display:block;
    margin-left: 0.5rem;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: none !important;
    border: 1px solid #b5b5b5;
    border-radius: 4px;
    font: normal normal 1rem 'Helvetica Neue', sans-serif;
    font-size: 12px !important;
    padding: 0.21rem 2.25rem;
}
#casestructure .col-2{
  width: 10.666667% !important;
}
.nme{
  text-align: left;
    padding-top: 5px;
    padding-left:15px;
}
.nme2{
  color: black;
    font-size: 13px;
    font-weight: 600; line-height:1.1;
    display:block; margin:0 0 .5rem;
}

#pmaTemplate *{
  font-family: Calibri, sans-serif;
  font-size: 25px !important;
}
.previewmodal-content .modal-content{  
  // width: 300% !important;
  // margin-left: -520px !important;
  width: 70rem !important;
  margin-left: -20rem !important;
}
.uploadFiles .file-name
{
  width: 15rem !important;
}
//Disable after sending docusign
.disablediv {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
.no-submission
{
  border: 2px solid;
  border-color: #ccc;
  margin-bottom: 0.75rem;
  span
  {
    text-align: center;
  }
}

.response-submission
{
  // border: 2px solid;
  // border: none;
  // border-color: #ccc;
  margin-bottom: 0.75rem;
  .action{
    margin:0 .5rem;
    cursor: pointer; float: left;
  }
  .file-name{
    margin:0; white-space:normal; word-wrap:break-word; width:calc(100% - 120px); text-align:left; float:left;
  }
}


.decision-timesheet
{
  width:50%;
  margin:0 0 .5rem;
  th{
  font-weight: bold;
  }
}

.uploadFiles
{
  margin-top: 10px;
  display: block;
  input.file-action-radio
  {
    box-sizing: border-box;
    height: 13px;
    width: 13px;
    position: relative;
    top: 2px;
    margin-right: 2px;
  }
  div.file-action {text-align:left;}

  .action{
    margin-left:0;
    cursor: pointer; float:left;
  }
  .file-name{
    margin:0; white-space:normal; word-wrap:break-word; width:15rem; float:left;
  } 
}
.uploadbtn{
    width: auto !important;
    margin: 10px 0px 10px 0px !important;
    background-color: #818B93 !important;
    border: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    box-shadow: none !important;
    border-radius: 5px 5px 0px 0px !important;
    color: white !important;
}
.historybtn{
    width: auto !important;
    margin: 10px 10px 10px 5px !important;
    background-color: #818B93 !important;
    border: none !important;
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 5px 5px 0px 0px !important;
    box-shadow: none !important;
    color: white !important;
}
.historybtn2{
  width: auto;
  margin: 10px 10px 10px 5px;
  background-color: white;
  border: 1px solid #B4B4B4;
  margin-bottom: 0;
  padding-bottom: 0;
  border-radius: 5px 5px 0px 0px;
  box-shadow: none;
  color:#818B93;
}
.file-action label{
  margin-right: 10px;
}
.fi-file-uploader-wrapper ul {margin:0; padding-left:0;
  font-size: 13px;
}


// @media only screen and (max-width: 1700px) {
//   .previewmodal-content .modal-content{  
//     width: 80% !important;
//     margin-left: calc(25%-10px) !important;
//   }
//}
.pmaButtonDisabled.btn-primary {margin:1rem 0 0;}

.includeDiv.includeDiv .form-check {display:none; position:absolute; top:-1rem; right:0;}
.includeDiv.includeDiv:nth-child(1) .form-check {display:block;}
.includeDiv.includeDiv:nth-child(2) .form-check {display:none;}
@media only screen and (max-width:850px) {
label.pmaSignatoryCheckbox {min-width:auto; line-height:normal;}
.includeDiv .col-sm-1:first-child label {font-size:11px}
}

@media only screen and (max-width:825px) {
  .pmaEmail {width:15rem;}
}
@media only screen and (max-width:810px) {
.input2 {width:100% !important; margin:0 0 .5rem;}
button.Exclude {margin:0 0 1rem;}
.includeDiv .form-check {left:3.7rem;}
.uploadFiles .file-name {width:6.7rem;}

}

@media only screen and (max-width:600px) {
  .pmaButton, .pmaButtonDisabled {min-width:100%}
  .fi-file-uploader-wrapper {display:block}
  .fi-file-uploader-wrapper .files {margin:0 .7rem .7rem; width:auto;}
  .pmasec .plainName .input2 {width:57% !important;}
  .pmasec input[type="text"] {width:100%;}
  .pmabtn2, .pmabtn3, .pmabtn4 {float:none !important; margin:1rem 0 0;}
  .pmaEmail.chckbox {width:calc(100% - 35px) !important; margin-right:1rem;}
  .form-check {text-align:right;}
  .uploadFiles div.file-action {width:190px;}
}
.signatureBtmLine {border-bottom:1px solid #ccc; padding:0 0 .5rem; margin-bottom:2.5rem;}
.decision-timesheet, .decision-timesheet th, .decision-timesheet td {border:1px solid #000}
.pagebreak {page-break-before:'always';}
.pma-page{.action{cursor: pointer;}}
i.fa.fa-trash{cursor: pointer;}
// grammarly-editor-plugin {
//   --grammarly-default-border-radius: 0px  !important;
// }

._loading_overlay_wrapper._loading_overlay_wrapper--active.css-79elbk
{
  height: 100vh;
  overflow-y: hidden;
}
    .hide--required .disableditem span.drop-title{
      cursor: default;
  
    }